import React from "react"
import { generateClassName } from "src/lib/ui/class-name-helper"
import styled, { css } from "styled-components"
import { color } from "@smoothincorg/smooth-ui"

type Props = JSX.IntrinsicElements["button"] & {
  height?: number
  backgroundColor?: keyof typeof color
}

const { getRootClassName } = generateClassName("Button")

// 一旦白以外なさそうじゃね？
const textColor = `#ffffff`

const Component: React.FC<Props> = ({ children, backgroundColor, ...props }) => {
  return (
    <button {...props} className={getRootClassName(props)} data-bg-color={backgroundColor}>
      {children}
    </button>
  )
}

const StyledComponent = styled(Component)`
  background: ${(props) => color[props.backgroundColor ?? "main"]};
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${textColor};

  ${(props) =>
    props.height &&
    css`
      height: ${props.height}px;
    `}

  * {
    fill: ${textColor};
  }

  &:disabled {
    background-color: ${color.mediumLightGray};
  }
`

export const Button = StyledComponent
