"use client"
import { Text } from "@smoothincorg/smooth-ui"
import axios from "axios"
import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import useSWR from "swr"
import { z } from "zod"
import { useValueRef } from "src/hooks/useValueRef"
import { pokeLoadingSchema } from "src/pages/api/get-loading-animation"

type ContainerProps = {
  height?: number
  text?: string | false
}

type Props = ContainerProps & {
  className?: string
  src: string[]
}

const Component: React.FC<Props> = ({ height = 200, text, ...props }) => {
  const inverValRef = useRef<NodeJS.Timer>()
  const [i, setI] = useState(0)
  const iRef = useValueRef(i)

  useEffect(() => {
    inverValRef.current = !inverValRef.current
      ? setInterval(() => {
          if (typeof props.src[iRef.current + 1] === "string") {
            setI(() => iRef.current + 1)
          } else {
            setI(0)
          }
        }, 1000)
      : inverValRef.current

    return () => clearInterval(inverValRef.current)
  }, [iRef, props.src])

  const src = props.src?.find((d) => d.includes("animated")) ?? props.src[i]

  return (
    <div className={props.className}>
      {/* eslint-disable-next-line @next/next/no-img-element */}
      <img
        src={src}
        style={{
          height,
          width: "auto",
        }}
        alt="loading"
      />
      {text !== false && <Text textAlign="center">Loading...</Text>}
    </div>
  )
}

const StyledComponent = styled(Component)`
  font-size: 1.8rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  flex-direction: column;

  > img {
    display: block;
    height: 200px;
    width: auto;
  }
`

const Container: React.FC<ContainerProps> = (props) => {
  const loadingAnimationSwr = useSWR("/api/get-loading-animation", async () => {
    const { data } = await axios.get<z.infer<typeof pokeLoadingSchema>>(
      "/api/get-loading-animation"
    )
    return data
  })

  const strFInder = (data: { [key: string]: string | Record<string, string> }): string[] => {
    if (!data) return []
    return Object.values(data)
      .map((d) => {
        if (typeof d === "string") return d
        if (typeof d === "object") return strFInder(d)
        return []
      })
      .flat()
  }

  const imageFinder = () => {
    const values = strFInder(loadingAnimationSwr.data)
      .filter((d) => typeof d === "string")
      .filter((d) => !d.includes("back") && !d.includes("shiny"))

    return values
  }

  if (!imageFinder().length) return null

  return <StyledComponent src={imageFinder()} {...props} />
}

export const PokeLoading = Container
