import { S3_ROOT } from "src/lib/constants/s3"
import { LIFF_URL } from "src/lib/constants/value"

type Type = "flex" | "text" | "imagemap"

export type V2TemplateMessagesType = {
  type: Type
  title: string
  altText: string
  text?: string
  baseUrl?: string
  contents: {
    type: string
    hero: {
      type: string
      url: string
      size: string
      aspectRatio: string
      aspectMode: string
      action: {
        type: string
        label: string
        uri: string
      }
    }
  }
}

export const v2TemplateMessages: V2TemplateMessagesType[] = [
  {
    type: "flex",
    title: "1.[flex画像のみ]入居申込のための審査LIFF:[Ver2.0] 本審査LIFF を送る",
    altText: "利用審査のために質問に回答してください！",
    contents: {
      type: "bubble",
      hero: {
        type: "image",
        url: `${S3_ROOT}/ver2_please_simplified_examination_for_kamonegi_flex/700.png`,
        size: "full",
        aspectRatio: "7:6",
        aspectMode: "cover",
        action: {
          type: "uri",
          label: "action",
          uri: `${LIFF_URL}/liff/examination-form`,
        },
      },
    },
  },
  {
    type: "flex",
    title:
      "2.[flex画像のみ][Ver2.0] 「チャットお部屋提案の希望条件を登録してください」とタイトルが入ったLIFF を送る",
    altText: "チャットお部屋提案の希望条件を登録してください",
    contents: {
      type: "bubble",
      hero: {
        type: "image",
        url: `${S3_ROOT}/register_search_house4/700.png`,
        size: "full",
        aspectRatio: "10:10",
        aspectMode: "cover",
        action: {
          type: "uri",
          label: "action",
          uri: `${LIFF_URL}/search-houses`,
        },
      },
    },
  },
]
