import { MessageType } from "src/features/chat/components/Message"
import { atom, selector } from "recoil"
import { Proposal, SimplifiedExamination, TalkRoom, User } from "src/features/chat/types"
import { TemplateMessagesType } from "src/features/chat/components/TemplateMessages"
import { MemoType } from "src/components/Memo"
import { Smoother } from "src/features/auth"

export type LineChatState = {
  checkedStatuses: {
    demanded: boolean
    undemanding: boolean
    action_required: boolean
    replied: boolean
    none: boolean
  }
  assignSmootherModalOpened: boolean
  fetchingMessages: boolean
  fetchingTalkRooms: boolean
  memos: (MemoType & {
    expanded?: boolean
  })[]
  memoFormOpened: boolean
  messages: MessageType[]
  memoForm: { id: number; title: string; body: string }
  searchBoxValue: string
  newMessage: string
  selectedSmootherId: number | "unassigned"
  passedUserId: number | null
  selectedUserId: number | null
  smoothers: Smoother[]
  statusFilterDropdownOpened: boolean
  talkRooms: TalkRoom[]
  timerIds: {
    fetchMessagesLooper: NodeJS.Timeout
    fetchTalkRoomsLooper: NodeJS.Timeout
    fetchTalkLooper: NodeJS.Timeout
    fireSearchBoxFetchRequest: NodeJS.Timeout
  }
  users: User[]
  latest_proposals: Proposal[]
  latest_simplified_examinations: SimplifiedExamination[]
  displayedSections: {
    assigneesSection: boolean
    talkRoomsSection: boolean
    chatSection: boolean
    talkRoomInfoSection: boolean
  }
  templateMessages: TemplateMessagesType
}

export const lineChatDefaultState: LineChatState = {
  checkedStatuses: {
    demanded: true,
    undemanding: true,
    action_required: true,
    replied: true,
    none: true,
  },
  assignSmootherModalOpened: false,
  fetchingMessages: false,
  fetchingTalkRooms: false,
  memos: null,
  memoFormOpened: false,
  messages: [],
  memoForm: { id: null, title: "", body: "" },
  searchBoxValue: "",
  newMessage: "",
  selectedSmootherId: null,
  passedUserId: null, // チャットルームリスト遷移時にクエリパラメータで渡されたユーザーのID
  selectedUserId: null, //チャットルームリストで選択されたユーザーのID
  smoothers: [],
  statusFilterDropdownOpened: false,
  talkRooms: [],
  timerIds: {
    fetchTalkLooper: null,
    fetchMessagesLooper: null,
    fetchTalkRoomsLooper: null,
    fireSearchBoxFetchRequest: null,
  },
  users: [],
  latest_proposals: [],
  latest_simplified_examinations: [],
  displayedSections: {
    assigneesSection: false,
    talkRoomsSection: false,
    chatSection: true,
    talkRoomInfoSection: false,
  },
  templateMessages: {
    available: false,
    data: [],
  },
}

export const lineChatState = atom({
  key: "LINE_CHAT",
  default: lineChatDefaultState,
})

export const lineChatMessagesState = selector<LineChatState["messages"]>({
  key: "LINE_CHAT/MESSAGES",
  get: (args) => args.get(lineChatState).messages,
  set: (args, newValue) => {
    args.set(lineChatState, (prevState) => {
      if (newValue instanceof Array) {
        return {
          ...prevState,
          messages: newValue,
        }
      }

      return prevState
    })
  },
})

export const lineChatTalkRoomsState = selector<LineChatState["talkRooms"]>({
  key: "LINE_CHAT/TALKROOMS",
  get: (args) => args.get(lineChatState).talkRooms,
  set: (args, newValue) => {
    args.set(lineChatState, (prevState) => {
      if (newValue instanceof Array) {
        return {
          ...prevState,
          talkRooms: newValue,
        }
      }

      return prevState
    })
  },
})
