import { color, font, generateClassName } from "@smoothincorg/smooth-ui"
import React, { memo, useCallback, useEffect, useMemo, useState } from "react"
import styled from "styled-components"
import { Border } from "src/components/Border"
import { Result } from "src/lib/notion/api-types"
import { boxShadow } from "src/lib/styles/variable"
import { useMessageForm } from "../hooks/useMessageForm"
import { SiNotion } from "react-icons/si"
import { isArray } from "src/lib/next-firebase-auth-edge/auth/validator"

export type TemplateMessagesType = {
  available: boolean
  data: Result[]
}

type Props = {
  className?: string
  templateMessages?: Result[]
} & Pick<ReturnType<typeof useMessageForm>, "appendMessage">

const { getRootClassName } = generateClassName("TemplateMessages")

// eslint-disable-next-line react/display-name
const Component: React.VFC<Props> = memo((props) => {
  const [category, setCategory] = useState<string>(null)

  const getMessageView = useCallback(
    (texts: TemplateMessagesType["data"][number]["properties"]["メッセージ文面"]) => {
      if (!texts.rich_text) {
        console.error("テンプレートメッセージ：メッセージ文面がない！", texts)
        return ""
      }
      const textNodes = texts.rich_text.map((text) => {
        if (text.annotations.code) {
          return `〔${text.plain_text}〕`
        }
        return text.plain_text
      })

      return textNodes.join("")
    },
    []
  )

  const categories = useMemo(() => {
    const value: [string, string][] = [["999_その他", "UNDEFINED"]]
    if (isArray(props.templateMessages) && props.templateMessages.length) {
      props.templateMessages.forEach((data) => {
        const targetCat = data.properties["カテゴリ"]

        if (!targetCat) {
          value.push(["999_その他", "UNDEFINED"])
          console.error("テンプレートメッセージ：カテゴリがない！", data)
          return
        }

        if (!targetCat.select) {
          console.error("テンプレートメッセージ：カテゴリがselectでない！", targetCat)
          return
        }

        const isIncluded = value.some((d) => {
          const id = d[1]
          return id === targetCat?.select?.id
        })

        if (isIncluded) return

        value.push([targetCat.select?.name, targetCat.select?.id])
      })
    }

    return value
      .sort(([a], [b]) => {
        if (!a || !b) {
          console.error("テンプレートメッセージ：番号がない！", a, b)
          return 0
        }
        const getIndex = (t: string) => Number(t.split("_")[0])
        return getIndex(a) - getIndex(b)
      })
      .map((d) => [d[0].split("_")[1], d[1]])
  }, [props.templateMessages])

  const targetMessages = useMemo(() => {
    if (!isArray(props.templateMessages)) {
      return []
    }

    const sortMessages = (messages: TemplateMessagesType["data"]) =>
      messages.sort(
        (a, b) =>
          a.properties["通し番号_カテゴリ内"].number - b.properties["通し番号_カテゴリ内"].number
      )
    if (category === "UNDEFINED") {
      return props.templateMessages.filter((message) => !message.properties["カテゴリ"])
    }

    return sortMessages(
      props.templateMessages?.filter((message) => {
        if (message.properties["カテゴリ"]) {
          return message.properties["カテゴリ"].select.id === category
        }
        return false
      })
    )
  }, [category, props.templateMessages])

  const handleClick = useCallback(
    (text: string) => {
      props.appendMessage(text)
    },
    [props]
  )

  useEffect(() => {
    if (!category && categories.length > 3) {
      setCategory(categories[0][1])
    }
  }, [categories, category])

  if (!props.templateMessages.length)
    return (
      <div className={`${props.className} loading`}>
        🤗テンプレートメッセージをNotionから読み込み中…😋
      </div>
    )

  return (
    <div className={getRootClassName(props)}>
      <ul className={`${props.className}-categories-list`}>
        {categories.map(([name, value]) => {
          return (
            <li className={`${props.className}-categories`} key={value}>
              <button
                className={`${props.className}-categories__button ${
                  value === category && "is-selected"
                }`}
                onClick={() => {
                  setCategory(value)
                }}
              >
                {name}
              </button>
              <Border color="lightGray" />
            </li>
          )
        })}
        <li>
          <a
            className={`${props.className}-categories__button`}
            target={`_blank`}
            rel="norefferer"
            href={`https://www.notion.so/smoothinc/6861e6128ed946d2a93d220b534b97dd`}
          >
            <SiNotion />
          </a>
        </li>
      </ul>
      {category && (
        <ul className={`${props.className}-message-list ${category && "category-selected"}`}>
          {targetMessages.map((d) => (
            <li key={d.id} className={`${props.className}-message`}>
              <button
                className={`${props.className}-message__button`}
                onClick={() => handleClick(getMessageView(d.properties["メッセージ文面"]))}
              >
                {getMessageView(d.properties["メッセージ文面"])}
              </button>
            </li>
          ))}
        </ul>
      )}
    </div>
  )
})

const StyledComponent = styled(Component)`
  padding-bottom: 0;
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;

  &.loading {
    font-size: 18px;
  }

  &-categories-list {
    width: 20%;
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
    overflow-x: scroll;
  }

  &-categories {
    display: block;

    &__button {
      ${font(14)};
      padding: 8px;
      font-weight: bold;
      text-align: left;
      width: 100%;

      &.is-selected {
        background-color: ${color.lightGray};
        border-color: ${color.lightGray};
      }
    }
  }

  &-message-list {
    width: 80%;
    padding: 8px 12px;
    background-color: ${color.lightGray};
    overflow-y: scroll;
  }

  &-message {
    margin-bottom: 18px;

    &__button {
      text-align: left;
      white-space: pre-wrap;
      word-break: break-all;
      display: block;
      font-size: 14px;
      border-radius: 6px;
      padding: 6px 10px;
      ${boxShadow.normal};
      background: ${color.white};

      &:focus {
        outline: none;
      }
      &:hover,
      &.is-selected {
        /* background: ${color.gradationBlue};
        color: #ffffff; */
      }
    }
  }
`

export const TemplateMessages = StyledComponent
