import { font, generateClassName, GrayRoundInput, GrayRoundTextArea } from "@smoothincorg/smooth-ui"
import styled from "styled-components"
import { Button } from "src/components/Button"
import { useHandleMemoForm } from "src/features/chat/hooks/useHandleMemoForm"
import { useSmootherMemos } from "src/features/chat/hooks/useSmootherMemo"

type Props = Pick<
  ReturnType<typeof useHandleMemoForm>,
  "memoForm" | "handleMemoTitleForm" | "handleMemoBodyForm" | "closeMemoForm"
> &
  Pick<ReturnType<typeof useSmootherMemos>, "saveMemo"> & {
    className?: string
  }

const { getRootClassName, className } = generateClassName("MemoForm")

const Component: React.FC<Props> = (props) => {
  return (
    <form onSubmit={props.saveMemo} className={getRootClassName(props)}>
      <section className="c-form__field p-line-client__memo-form-title">
        <GrayRoundInput
          type="text"
          onChange={props.handleMemoTitleForm}
          value={props.memoForm.title}
          placeholder="メモのタイトルを入力して下さい"
          className="c-form__field-input"
        />
      </section>
      <section className="c-form__field p-line-client__memo-form-body">
        <GrayRoundTextArea
          onChange={props.handleMemoBodyForm}
          value={props.memoForm.body}
          placeholder="メモの本文を入力して下さい"
          className="c-form__field-input"
        />
      </section>
      <div className={`${className}__actions`}>
        <Button
          onClick={props.closeMemoForm}
          backgroundColor="gray"
          className="c-button c-button--outline-dark-gray u-flex-1"
        >
          キャンセル
        </Button>
        <Button className="c-button c-button--agent u-flex-1 u-ml-m">保存</Button>
      </div>
    </form>
  )
}

export const MemoForm = styled(Component)`
  > section {
    margin-bottom: 8px;
  }

  .${className} {
    &__actions {
      display: flex;

      > .smooth-ui-Button {
        ${font(12)};
        font-weight: bold;
        padding: 4px 8px;
        margin-right: 8px;
      }
    }
  }
`
