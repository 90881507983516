import { color, generateClassName } from "@smoothincorg/smooth-ui"
import React from "react"
import styled from "styled-components"

type Props = JSX.IntrinsicElements["input"]

const { getRootClassName, className } = generateClassName("CircledInput")

const Component: React.FC<Props> = (props) => {
  const { ...inputProps } = props
  return (
    <label htmlFor={props.id} className={getRootClassName(props)}>
      <input {...inputProps} className={`${className}__input`} />
    </label>
  )
}

const StyledComponent = styled(Component)`
  border: 2px solid ${color.mediumLightGray};
  border-radius: 25px;
`

export const CircledInput = StyledComponent
