import { generateClassName } from "@smoothincorg/smooth-ui"
import React from "react"
import styled from "styled-components"
import { CircledInput } from "src/components/CircledInput"
import { useSearchBox } from "src/features/chat/hooks/useSearchBox"

type Props = Pick<ReturnType<typeof useSearchBox>, "handleSearchBox"> & {
  className?: string
}

const { getRootClassName } = generateClassName("SearchBox")

const Component: React.FC<Props> = (props) => {
  return (
    <div className={getRootClassName(props)}>
      <CircledInput
        type="text"
        onChange={props.handleSearchBox}
        placeholder="氏名・電話番号で検索"
        className="p-line-client__talk-room-search-box"
      />
    </div>
  )
}

export const SearchBox = styled(Component)`
  > label {
    padding: 4px 8px;
  }
`
