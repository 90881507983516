import React, { useEffect } from "react"
import { useChatRoomView } from "../hooks/useChatRoomView"
import { useFetchMessages } from "../hooks/useFetchMessages"
import { useLineChatState } from "../hooks/useLineChatState"
import styled, { css } from "styled-components"
import { TalkRoomInfoContainer } from "../containers/TalkRoomInfoContainer"
import { font, mq } from "@smoothincorg/smooth-ui"
import { TalkRoomList } from "./TalkRoomList"
import { TalkSection } from "./TalkSection"
import { LineChatState } from "src/store/lineChatState"
import { Result } from "src/lib/notion/api-types"
import { HEADER_HEIGHT } from "src/components/Header/Header.css"
import { useMessageForm } from "../hooks/useMessageForm"
import { useFetchMemos } from "../hooks/useFetchMemos"
import { useSelectedTalkRoom } from "../hooks/useSelectedTalkRoom"
import { useValueRef } from "src/hooks/useValueRef"

type Props = {
  className?: string
  displayedSections: LineChatState["displayedSections"]
} & ContainerProps

const Component: React.FC<Props> = (props) => {
  return (
    <div className={props.className}>
      <TalkRoomList />
      <TalkSection templateMessages={props.templateMessages} />
      <TalkRoomInfoContainer />
    </div>
  )
}

const StyledComponent = styled(Component)(
  (props) => css`
    ${font(15)};
    display: flex;
    justify-content: flex-start;

    ${mq("sp_wide")} {
      ${font(13)};
    }

    > .smooth-ui-TalkRoomList {
      width: 300px;
      height: calc(100vh - ${HEADER_HEIGHT}px);

      ${mq("sp_wide")} {
        width: 100%;
        display: ${props.displayedSections.talkRoomsSection ? "block" : "none"};
      }
    }

    > .smooth-ui-TalkRoomInfo {
      width: 360px;
      margin-left: auto;
      height: calc(100vh - ${HEADER_HEIGHT}px);
      overflow: scroll;

      ${mq("sp_wide")} {
        width: 100%;
        display: ${props.displayedSections.talkRoomInfoSection ? "block" : "none"};
      }
    }

    > .smooth-ui-TalkSection {
      width: calc(100% - 300px - 360px);

      ${mq("sp_wide")} {
        width: 100%;
        display: ${props.displayedSections.chatSection ? "block" : "none"};
      }
    }
  `
)

type ContainerProps = {
  templateMessages: Result[] | Error
}

export const Container: React.VFC<ContainerProps> = (props) => {
  const { lineChatState: state } = useLineChatState()
  const { initializeChatRoomView } = useChatRoomView()
  const { startFetchMessageLooper, scrollToBottomMessage, stopFetchMessagesLooper } =
    useFetchMessages()
  const { fetchInitialMessages, resetMessages } = useFetchMessages()
  const { resetMessageForm } = useMessageForm()
  const { fetchMemos } = useFetchMemos()
  const { selectedTalkRoom } = useSelectedTalkRoom()
  const [fetchedUserId, setFetchedUserId] = React.useState<number | null>(null)
  const lineChatState = useValueRef(state)?.current

  useEffect(() => {
    initializeChatRoomView()
    startFetchMessageLooper()
    scrollToBottomMessage()

    return () => {
      stopFetchMessagesLooper()
    }
  }, [
    initializeChatRoomView,
    scrollToBottomMessage,
    startFetchMessageLooper,
    stopFetchMessagesLooper,
  ])

  useEffect(() => {
    if (selectedTalkRoom?.userId && fetchedUserId !== selectedTalkRoom.userId) {
      resetMessageForm()
      resetMessages()
      Promise.all([fetchMemos(selectedTalkRoom.userId), fetchInitialMessages()]).then(() => {
        setFetchedUserId(selectedTalkRoom.userId)
      })
    }
  }, [
    fetchInitialMessages,
    fetchMemos,
    fetchedUserId,
    resetMessageForm,
    resetMessages,
    selectedTalkRoom,
  ])

  return (
    <StyledComponent
      templateMessages={props.templateMessages}
      displayedSections={lineChatState.displayedSections}
    />
  )
}

export const LineChat = Container
