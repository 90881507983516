import { useEffect } from "react"
import { useRecoilState } from "recoil"
import { useSmoothApi } from "src/hooks/useSmoothApi"
import { camelCase } from "src/lib/aikagi"
import { SMOOTH_LINE_API_URL } from "src/lib/constants/value"
import { LineChatRes } from "../types"
import useSWR from "swr"
import { useRouter } from "next/router"
import { lineChatState } from "src/store/lineChatState"
import { useValueRef } from "src/hooks/useValueRef"

export const useLineChatSWR = (userId?: number) => {
  const { getWithToken } = useSmoothApi()
  const router = useRouter()

  const url = userId
    ? `${SMOOTH_LINE_API_URL}/base_api/smoother/line_chat?user_id=${userId}`
    : `${SMOOTH_LINE_API_URL}/base_api/smoother/line_chat`

  const swr = useSWR(router.isReady ? url : null, async (url) => {
    const res = await getWithToken(url)
    const { data: serverData } = res.data as {
      data: LineChatRes
    }
    const data = camelCase(serverData)
    return data
  })

  return { ...swr, url }
}

export const useLineChatState = () => {
  const [state, setState] = useRecoilState(lineChatState)
  // API(/base_api/smoother/line_chat)に渡すユーザーIDはstateのselectedUserIdではなく、passedUserIdを渡す
  // クエリパラメータで指定されたときだけチャットルームリストの先頭に表示するため
  const { data, error, isValidating } = useLineChatSWR(state.passedUserId)
  const stateRef = useValueRef(state)

  useEffect(() => {
    if (data) {
      setState((current) => {
        return {
          ...current,
          smoothers: data.smoothers || [],
          talkRooms: data.chatRooms || [],
          users: data.users || [],
          latest_proposals: data.latestProposals || [],
        }
      })
    }
  }, [data, setState])

  const setLineChatState: typeof setState = (setStateAction) => {
    const newState =
      typeof setStateAction === "function"
        ? // [FIXME]setTimeOutの中でsetStateを呼ぶと、setStateActionが最新のstateにならないんだけど剥がすのがめんどいので
          //一旦refで保持しておく
          setStateAction(stateRef.current)
        : setStateAction

    setState(newState)
  }

  return {
    lineChatState: state,
    isInit: true,
    setLineChatState,
    error,
    isValidating,
  }
}
