import { useCallback, useMemo } from "react"
import { useSelectedTalkRoom } from "./useSelectedTalkRoom"
import { useFetchTalkRooms } from "./useFetchTalkRooms"
import { useLineChatState } from "./useLineChatState"
import { axios } from "src/lib/axios"
import { Smoother } from "src/features/auth"

export const useAssignSmoother = () => {
  const { lineChatState, setLineChatState } = useLineChatState()
  const { selectedTalkRoom } = useSelectedTalkRoom()
  const { fetchTalkRooms } = useFetchTalkRooms()

  const toggleAssignment = useCallback(
    async (smoother: Smoother) => {
      if (!smoother) {
        alert("担当者が選択されていません。")
        return
      }

      if (!selectedTalkRoom) {
        alert("トークルームが選択されていません。")
        return
      }

      const smootherIsAssigned = !!selectedTalkRoom.agentId

      if (smootherIsAssigned) {
        if (!confirm("担当から外しますか？")) {
          return
        }
      } else {
        if (!confirm("担当者に設定しますか？")) {
          return
        }
      }

      const requestPath = ""
      const params = { smoother_id: smoother.id }

      axios
        .patch(requestPath, params)
        .then((response) => {
          const talkRooms = JSON.parse(response.data.chatRooms).data

          setLineChatState((prevState) => {
            return { ...prevState, talkRooms: talkRooms }
          })
        })
        .catch(() => {
          alert("エラーが発生しました。")
        })
    },
    [selectedTalkRoom, setLineChatState]
  )

  const closeAssignSmootherModal = useCallback(() => {
    setLineChatState((prevState) => {
      return { ...prevState, assignSmootherModalOpened: false }
    })
  }, [setLineChatState])

  const selectSmoother = (selectedSmootherId: number | "unassigned") => {
    setLineChatState((prevState) => {
      // 選択済みのSmootherを再度選択した時は、選択を解除する
      if (selectedSmootherId === prevState.selectedSmootherId) {
        return { ...prevState, selectedSmootherId: null }
      }
      return { ...prevState, selectedSmootherId: selectedSmootherId }
    })

    fetchTalkRooms()
  }

  const openAssignSmootherModal = useCallback(() => {
    setLineChatState((prevState) => {
      return { ...prevState, assignSmootherModalOpened: true }
    })
  }, [setLineChatState])

  const assignSmootherModalOpened = useMemo(
    () => lineChatState.assignSmootherModalOpened,
    [lineChatState.assignSmootherModalOpened]
  )

  return {
    toggleAssignment,
    closeAssignSmootherModal,
    selectSmoother,
    openAssignSmootherModal,
    assignSmootherModalOpened,
  }
}
