import { color, generateClassName } from "@smoothincorg/smooth-ui"
import React from "react"
import styled from "styled-components"
import { TalkRoom } from "src/features/chat/types"

type Props = {
  talkRoom: TalkRoom
  className?: string
}

const { getRootClassName } = generateClassName("TalkHeaderStatusDisplay")

export const Component: React.VFC<Props> = (props) => {
  const talkRoom = props.talkRoom

  if (!talkRoom) {
    return null
  }

  const ClassNames = [getRootClassName(props)].join(" ")

  return (
    <div className={ClassNames} data-status={props.talkRoom.statusJa}>
      {props.talkRoom.statusJa}
    </div>
  )
}

const StyledComponent = styled(Component)`
  color: #ffffff;
  padding: 4px 6px;
  border-radius: 25px;
  &[data-status='督促済'] {
    background: ${color.darkBlue};
  }
  &[data-status='未督促'] {
    background: ${color.lightNavy};
  }
  &[data-status='要対応'] {
    background: ${color.gradationRed};
  }
  &[data-status='返信済'] {
    background: ${color.gradationBlue};
  }
`

export const TalkHeaderStatusDisplay = StyledComponent
