import axios from "axios"
import useSWR from "swr"

type Obj = {
  object: "list"
  results: [
    {
      object: "page"
      id: "032adcc7-93fa-450e-938f-d213da15c8eb"
      created_time: "2022-07-06T09:34:00.000Z"
      last_edited_time: "2022-07-06T09:46:00.000Z"
      created_by: {
        object: "user"
        id: "297a34f8-208b-4026-bdc0-8dfcf848ee41"
      }
      last_edited_by: {
        object: "user"
        id: "297a34f8-208b-4026-bdc0-8dfcf848ee41"
      }
      cover: null
      icon: null
      parent: {
        type: "database_id"
        database_id: "865e50fa-ff0d-422d-b09f-f16967f45f31"
      }
      archived: false
      properties: {
        contract_id: {
          id: "oqyP"
          type: "number"
          number: 1000
        }
        ID: {
          id: "title"
          type: "title"
          title: [
            {
              type: "text"
              text: {
                content: "2"
                link: null
              }
              annotations: {
                bold: false
                italic: false
                strikethrough: false
                underline: false
                code: false
                color: "default"
              }
              plain_text: "2"
              href: null
            }
          ]
        }
      }
      url: "https://www.notion.so/2-032adcc793fa450e938fd213da15c8eb"
    },
    {
      object: "page"
      id: "625d44a5-aed6-4903-8851-0563fc0513a1"
      created_time: "2022-07-06T09:34:00.000Z"
      last_edited_time: "2022-07-06T09:46:00.000Z"
      created_by: {
        object: "user"
        id: "297a34f8-208b-4026-bdc0-8dfcf848ee41"
      }
      last_edited_by: {
        object: "user"
        id: "297a34f8-208b-4026-bdc0-8dfcf848ee41"
      }
      cover: null
      icon: null
      parent: {
        type: "database_id"
        database_id: "865e50fa-ff0d-422d-b09f-f16967f45f31"
      }
      archived: false
      properties: {
        contract_id: {
          id: "oqyP"
          type: "number"
          number: 9999
        }
        ID: {
          id: "title"
          type: "title"
          title: [
            {
              type: "text"
              text: {
                content: "1"
                link: null
              }
              annotations: {
                bold: false
                italic: false
                strikethrough: false
                underline: false
                code: false
                color: "default"
              }
              plain_text: "1"
              href: null
            }
          ]
        }
      }
      url: "https://www.notion.so/1-625d44a5aed6490388510563fc0513a1"
    }
  ]
  next_cursor: null
  has_more: false
  type: "page"
  page: unknown
}

export const useNotionSaikenSWR = (contractId: number) => {
  const results: Obj["results"][number][] = []

  const fetcher = async (url: string) => {
    const fetchRes = async (query: Record<string, string>) => {
      const res = await axios.get(url.split("?")[0], {
        params: {
          contract_id: contractId,
          ...query,
        },
      })

      for (const row of res.data.results) {
        results.push(row)
      }

      if (res.data.has_more) {
        await fetchRes({ start_cursor: res.data.next_cursor })
      }
    }

    await fetchRes({})

    return { results }
  }

  const swr = useSWR(
    contractId ? "/api/assignment-of-accounts-receivable" + "?contract_id=" + contractId : null,
    fetcher
  )

  if (swr.error) {
    console.error(swr.error)
    return
  }

  return swr
}
