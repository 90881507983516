import React, { memo } from "react"
import { TalkRoom as TalkRoomType, User } from "src/features/chat/types"
import { useGetBaseData } from "src/features/chat/hooks/useGetBaseData"
import { LineChatState } from "src/store/lineChatState"
import { useToggleStatusFilter } from "src/features/chat/hooks/useToggleStatusFilter"
import { useRefreshTalkRooms } from "src/features/chat/hooks/useRefreshTalkRooms"
import { useChatRoomView } from "src/features/chat/hooks/useChatRoomView"
import { useSelectTalkRoom } from "src/features/chat/hooks/useSelectTalkRoom"
import { StatusFilter } from "./StatusFilter"
import { SearchBoxContainer } from "src/features/chat/containers/SearchBoxContainer"
import { RefreshButton } from "./RefreshButton"
import { TalkRoom } from "./TalkRoom"
import styled from "styled-components"
import { generateClassName } from "@smoothincorg/smooth-ui"
import { useLineChatState } from "../hooks/useLineChatState"
import { useSelectedTalkRoom } from "../hooks/useSelectedTalkRoom"
import { StatusFilterDropdown } from "./StatusFilterDropdown"
import { HEADER_HEIGHT } from "src/components/Header/Header.css"

type Props = {
  user: User
  talkRooms: TalkRoomType[]
  className?: string
  selectedUserId?: number
} & Pick<LineChatState, "displayedSections"> &
  Pick<ReturnType<typeof useGetBaseData>, "getUser"> &
  Pick<
    ReturnType<typeof useToggleStatusFilter>,
    "toggleStatusFilterCheckbox" | "toggleStatusFilterDropdown" | "statusFilterDropdownOpened"
  > &
  Pick<ReturnType<typeof useRefreshTalkRooms>, "refreshTalkRooms"> &
  Pick<
    ReturnType<typeof useChatRoomView>,
    "talkRoomsContainerRef" | "talkScrollCallback" | "openChatSection"
  > &
  Pick<ReturnType<typeof useSelectTalkRoom>, "selectTalkRoom">

const { getRootClassName, className } = generateClassName("TalkRoomList")

// eslint-disable-next-line react/display-name
const Component: React.VFC<Props> = memo((props) => {
  return (
    <div className={getRootClassName(props)}>
      <div className={`${className}__search`}>
        <SearchBoxContainer />

        <StatusFilterDropdown />
        <RefreshButton refreshTalkRooms={props.refreshTalkRooms} />

        <StatusFilter toggleStatusFilterDropdown={props.toggleStatusFilterDropdown} />
      </div>

      <div
        className={`${className}__body`}
        onScroll={props.talkScrollCallback}
        ref={props.talkRoomsContainerRef}
      >
        <div className="p-line-client__talk-rooms">
          {props.talkRooms.map((talkRoom) => {
            const user = props.getUser(talkRoom?.userId)

            return (
              <TalkRoom
                // Key
                key={talkRoom.id}
                // States
                selectedUserId={props.selectedUserId}
                talkRoom={talkRoom}
                user={user}
                // Functions
                openChatSection={props.openChatSection}
                selectTalkRoom={props.selectTalkRoom}
              />
            )
          })}
        </div>
      </div>
    </div>
  )
})

const StyledComponent = styled(Component)`
  position: relative;

  .${className} {
    &__search {
      height: 56px;
      box-shadow: 0 6px 12px rgb(0 0 0 / 6%);
      background-color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      width: 100%;

      > button {
        height: 29px;
      }

      svg {
        height: 100%;
        width: 23px;
      }

      .smooth-ui-SearchBox {
        margin-right: 8px;
      }
    }

    &__body {
      margin-top: 56px;
      overflow: scroll;
      height: calc(100vh - 56px - ${HEADER_HEIGHT}px);
    }
  }
`
export const TalkRoomList: React.VFC = () => {
  const { lineChatState } = useLineChatState()
  const { openChatSection, talkScrollCallback, talkRoomsContainerRef } = useChatRoomView()

  const { refreshTalkRooms } = useRefreshTalkRooms()
  const { selectTalkRoom } = useSelectTalkRoom()
  const { toggleStatusFilterCheckbox, toggleStatusFilterDropdown } = useToggleStatusFilter()

  const { selectedTalkRoomUser: user } = useSelectedTalkRoom()
  const { getUser } = useGetBaseData()

  return (
    <StyledComponent
      // States
      user={user}
      selectedUserId={lineChatState.selectedUserId}
      statusFilterDropdownOpened={lineChatState.statusFilterDropdownOpened}
      talkRooms={lineChatState.talkRooms}
      displayedSections={lineChatState.displayedSections}
      // References
      talkRoomsContainerRef={talkRoomsContainerRef}
      // Functions
      getUser={getUser}
      openChatSection={openChatSection}
      refreshTalkRooms={refreshTalkRooms}
      selectTalkRoom={selectTalkRoom}
      toggleStatusFilterCheckbox={toggleStatusFilterCheckbox}
      toggleStatusFilterDropdown={toggleStatusFilterDropdown}
      talkScrollCallback={talkScrollCallback}
    />
  )
}
