import React from "react"
import { MemoForm } from "src/features/chat/components/MemoForm"
import { useHandleMemoForm } from "../hooks/useHandleMemoForm"
import { useSmootherMemos } from "../hooks/useSmootherMemo"

export const MemoFormContainer: React.VFC = () => {
  const { memoForm, closeMemoForm, handleMemoBodyForm, handleMemoTitleForm } = useHandleMemoForm()

  const { saveMemo } = useSmootherMemos()

  return (
    <MemoForm
      // States
      memoForm={memoForm}
      // Functions
      closeMemoForm={closeMemoForm}
      saveMemo={saveMemo}
      handleMemoTitleForm={handleMemoTitleForm}
      handleMemoBodyForm={handleMemoBodyForm}
    />
  )
}
