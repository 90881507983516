import React, { useCallback, useMemo } from "react"
import { useRecoilState } from "recoil"
import { MemoType } from "src/components/Memo"
import { lineChatState } from "src/store/lineChatState"

export const useHandleMemoForm = () => {
  const [state, setState] = useRecoilState(lineChatState)

  const memoForm = useMemo(() => state.memoForm, [state.memoForm])

  const resetMemoForm = useCallback(() => {
    setState((prevState) => {
      const memoForm = { ...prevState.memoForm }

      memoForm.id = null
      memoForm.title = ""
      memoForm.body = ""

      return { ...prevState, memoFormOpened: false, memoForm }
    })
  }, [setState])

  const handleMemoBodyForm = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      e.persist()

      setState((prevState) => {
        const memoForm = { ...prevState.memoForm }

        memoForm.body = e.target.value

        return { ...prevState, memoForm }
      })
    },
    [setState]
  )

  const handleMemoTitleForm = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.persist()

      setState((prevState) => {
        const memoForm = { ...prevState.memoForm }

        memoForm.title = e.target.value

        return { ...prevState, memoForm: memoForm }
      })
    },
    [setState]
  )

  const openMemoForm = useCallback(
    (memo: MemoType) => {
      setState((prevState) => {
        const memoForm = { ...prevState.memoForm }

        if (memo) {
          memoForm.id = memo.id
          memoForm.title = memo.title
          memoForm.body = memo.body
        }

        return { ...prevState, memoForm, memoFormOpened: true }
      })
    },
    [setState]
  )

  const memoFormOpened = useMemo(() => state.memoFormOpened, [state.memoFormOpened])

  const closeMemoForm = useCallback(() => {
    resetMemoForm()
    setState((prevState) => {
      return { ...prevState, memoFormOpened: false }
    })
  }, [resetMemoForm, setState])

  return {
    memoForm,
    handleMemoBodyForm,
    handleMemoTitleForm,
    openMemoForm,
    memoFormOpened,
    closeMemoForm,
  }
}
