import { ChangeEvent, useCallback, useRef } from "react"
import { useRecoilState } from "recoil"
import { lineChatState } from "src/store/lineChatState"
import { useFetchTalkRooms } from "./useFetchTalkRooms"
import { useDebounceState } from "src/hooks/useDebounce"

export const useSearchBox = () => {
  const [state, setState] = useRecoilState(lineChatState)
  const { fetchTalkRooms } = useFetchTalkRooms()
  const debouncedSearchBoxValue = useDebounceState(state.searchBoxValue, 2000)
  const fetchedWordRef = useRef<string>()

  const handleSearchBox = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      e.persist()
      setState((prevState) => {
        return { ...prevState, searchBoxValue: e.target.value }
      })
    },
    [setState]
  )

  const bindSearchBoxEvent = useCallback(async () => {
    if (debouncedSearchBoxValue && fetchedWordRef.current !== debouncedSearchBoxValue) {
      fetchedWordRef.current = debouncedSearchBoxValue
      await fetchTalkRooms()
    }
  }, [fetchTalkRooms, debouncedSearchBoxValue])

  return {
    handleSearchBox,
    bindSearchBoxEvent,
  }
}
