const prefix = "smooth-ui" as const

type PlaceholderType = string | number | boolean | bigint

type Join<T extends readonly PlaceholderType[], S extends string> = T extends readonly [
  infer P,
  ...infer R
]
  ? P extends PlaceholderType
    ? [] extends R
      ? P
      : R extends readonly PlaceholderType[]
      ? `${P}${S}${Join<R, S>}`
      : `${P}${S}`
    : string
  : [] extends T
  ? string
  : string

function join<T extends readonly PlaceholderType[], S extends string = ",">(
  array: T,
  separator?: S
) {
  return array.join(separator) as Join<T, S>
}

export const generateClassName = <T extends string, C extends string[]>(
  componentName: T,
  className?: C
) => {
  const componentClassName: `${typeof prefix}-${T}` = `${prefix}-${componentName}`

  if (!className) return componentClassName

  const generatedClassName: `${typeof prefix}-${T} ${Join<C, " ">}` = `${componentClassName} ${join(
    className,
    " "
  )}`

  return generatedClassName
}
