import { SMOOTH_SMOOTHER_API_URL } from "src/lib/constants"
import { useSmoothApi } from "../useSmoothApi"
import useSWR from "swr"
import { camelCase } from "src/lib/aikagi"

type Contract = {
  id: number
  contractedOn: string
  disabled: string
  enabled: boolean
  interestAmount: number
  interestRate: number
  loan: number
  numberOfPayment: number
  paidAmount: number
  reimbursedOn: string
  status: string
  version: number
  createdAt: string
  updatedAt: string
}

export const useProposalsContractSWR = (proposalId: number) => {
  const { getWithToken } = useSmoothApi()
  const url = SMOOTH_SMOOTHER_API_URL + "/proposals/" + proposalId + "/contract"

  const fetcher = async () => {
    const res = await getWithToken(url)

    if (res.data) {
      return camelCase(res.data.contract) as Contract
    }
  }

  const swr = useSWR(proposalId ? url : null, fetcher)

  return swr
}
