import React, { useMemo } from "react"
import { useRecoilState } from "recoil"
import { Messages } from "src/features/chat/components/Messages"
import { useChatRoomView } from "../hooks/useChatRoomView"
import { useFetchMessages } from "../hooks/useFetchMessages"
import { useGetBaseData } from "../hooks/useGetBaseData"
import { useSelectedTalkRoom } from "../hooks/useSelectedTalkRoom"
import { lineChatMessagesState } from "src/store/lineChatState"

export const MessagesContainer: React.VFC = () => {
  const [messages] = useRecoilState(lineChatMessagesState)
  const { getUser, getSmoother } = useGetBaseData()
  const { selectedTalkRoom } = useSelectedTalkRoom()
  const { chatScrollCallback } = useChatRoomView()
  const { messagesContainerRef } = useFetchMessages()

  const user = useMemo(() => {
    if (!selectedTalkRoom) return null
    return getUser(selectedTalkRoom?.userId)
  }, [getUser, selectedTalkRoom])

  return (
    <Messages
      chatScrollCallback={chatScrollCallback}
      // States
      messages={messages}
      user={user}
      // References
      messagesContainerRef={messagesContainerRef}
      // Functions
      getSmoother={getSmoother}
    />
  )
}
