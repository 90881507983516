import React, { useContext, useState } from "react"
import styled from "styled-components"
import { useGetBaseData } from "../hooks/useGetBaseData"
import { useSelectedTalkRoom } from "../hooks/useSelectedTalkRoom"
import { Proposal, User } from "../types"
import {
  GrDocumentPpt,
  GrGroup,
  GrHomeRounded,
  GrUserManager,
  GrDocumentVerified,
} from "react-icons/gr"
import { GiSpookyHouse } from "react-icons/gi"
import { VscJson } from "react-icons/vsc"
import { useInitialGurusakuMessagesSWR } from "./InitialGurusakuMessagesClipboard"
import { SnackBarCard } from "src/lib/ui/snack-bar-card"
import { Card } from "src/components/page-directory/Card"
import { Title } from "@smoothincorg/smooth-ui"
import { SMOOTH_SMOOTHER_API_URL, V1_OPE_URL } from "src/lib/constants/value"
import {
  MainExaminationScore,
  useLatestMainExaminationsSWR,
} from "src/hooks/useMainExaminationScoresSWR"
import { useSmoothApi } from "src/hooks/useSmoothApi"
import { ModalContext } from "src/pages/admin/chat"
import { MovingApplication } from "src/hooks/useMovingApplicationsSWR/types"
import { useMovingApplications } from "../hooks/useMovingApplications"
import Link from "next/link"
import { BiUser } from "react-icons/bi"

type Props = {
  user: User
  className?: string
  latestProposal: Proposal
  initialGurusakuMessagesSWR: ReturnType<
    typeof useInitialGurusakuMessagesSWR
  >["initialGurusakuMessagesSWR"]
  actionCommonProps: {
    onMouseLeave: () => void
    onMouseEnter: React.MouseEventHandler<HTMLAnchorElement | HTMLButtonElement>
  }
  actionTitle: string
  mainExaminationScore?: MainExaminationScore
  latestMovingApplication?: MovingApplication
  handleMainExaminationResultChange: () => void
}

const MESSAGES_TEXTS = ["グループ作成後メッセージ", "物件共有メッセージ"] as const

const Component: React.VFC<Props> = (props) => {
  const [_, setModal] = useContext(ModalContext)
  return (
    <Card className={props.className}>
      <Title size={14} as="h3">
        {props.actionTitle}
      </Title>
      <div className={`${props.className}__actions`}>
        <Link
          href={`/admin/users/${props.user.id}`}
          data-name={`ユーザー詳細を見る`}
          {...props.actionCommonProps}
          target="_blank"
        >
          <BiUser />
        </Link>
        <a
          href={V1_OPE_URL + `/admin/users/${props.user.id}`}
          target="_blank"
          data-name={`ユーザー詳細を見る（OPE）`}
          {...props.actionCommonProps}
        >
          <GrUserManager />
        </a>
        <a
          href={V1_OPE_URL + `/admin/proposals/${props.latestProposal?.id}`}
          target="_blank"
          data-name={`最新のProposalを開く`}
          {...props.actionCommonProps}
        >
          <GrDocumentPpt />
        </a>
        <a
          href={V1_OPE_URL + `/admin/need_check_can_introduce_properties?user_id=${props.user?.id}`}
          target="_blank"
          data-name={`物件の掲載確認`}
          {...props.actionCommonProps}
        >
          <GrHomeRounded />
        </a>
        {props.initialGurusakuMessagesSWR.data?.map((message, i) => (
          <a
            key={message[0]}
            data-name={MESSAGES_TEXTS[i]}
            onClick={() => {
              import("copy-to-clipboard").then(({ default: copy }) => {
                copy(message.join("\n"))
                SnackBarCard.fire({ title: "コピーしました" })
              })
            }}
            {...props.actionCommonProps}
          >
            <GrGroup />
          </a>
        ))}
        {props.mainExaminationScore &&
          !["通過", "否認"].includes(props.mainExaminationScore.result) && (
            <button
              {...props.actionCommonProps}
              data-name={`${props.mainExaminationScore.result ? "審査GO！" : "審査NO GO…"}に変更`}
              onClick={() => props.handleMainExaminationResultChange()}
            >
              <GrDocumentVerified />
            </button>
          )}
        {props.latestMovingApplication && (
          <a
            href={V1_OPE_URL + `/admin/moving_applications/${props.latestMovingApplication?.id}`}
            target="_blank"
            data-name={`Latest Adopted MovingApplication`}
            {...props.actionCommonProps}
          >
            <GiSpookyHouse />
          </a>
        )}
        {props.latestProposal && (
          <button
            {...props.actionCommonProps}
            data-name={`Display Latest Proposal JSON`}
            onClick={() =>
              setModal((c) => ({
                ...c,
                content: (
                  <div
                    style={{
                      background: "white",
                      overflow: "scroll",
                      height: "100vh",
                    }}
                  >
                    <pre style={{ fontSize: 14 }}>
                      {JSON.stringify(props.latestProposal, null, "\t")}
                    </pre>
                  </div>
                ),
              }))
            }
          >
            <VscJson />
          </button>
        )}
      </div>
    </Card>
  )
}

const StyledComponent = styled(Component)`
  &__actions {
    display: flex;
    padding: 8px 0;

    > * {
      height: 20px;
      margin-right: 16px;

      > svg {
        height: 100%;
        width: 20px;
      }
    }
  }
`

const Container: React.VFC = () => {
  const DEFAULT_ACTION_TITLE = "アクション"
  const [actionTitle, setActionTitle] = useState(DEFAULT_ACTION_TITLE)
  const { getProposal } = useGetBaseData()
  const { selectedTalkRoomUser } = useSelectedTalkRoom()
  const { patchWithToken } = useSmoothApi()
  const latestProposal = getProposal(selectedTalkRoomUser?.latestProposalId)

  const { getAdoptedMovingApplication } = useMovingApplications()

  const { latestMainExaminationScoreSWR } = useLatestMainExaminationsSWR(latestProposal?.id)

  const mainExaminationScoreUrl = `${SMOOTH_SMOOTHER_API_URL}/main_examination_scores/${latestMainExaminationScoreSWR.data?.id}`

  const onMouseEnter: React.MouseEventHandler<HTMLAnchorElement | HTMLButtonElement> = (e) => {
    setActionTitle(e.currentTarget.dataset["name"])
  }

  const actionCommonProps = {
    onMouseLeave: () => setActionTitle(DEFAULT_ACTION_TITLE),
    onMouseEnter,
  }

  const { initialGurusakuMessagesSWR } = useInitialGurusakuMessagesSWR(latestProposal?.id)

  const handleMainExaminationResultChange = async () => {
    const result = confirm("OK?")
    if (result) {
      SnackBarCard.fire({ title: "変更中…" })
      const res = await patchWithToken(mainExaminationScoreUrl, {
        main_examination_score: {
          result: latestMainExaminationScoreSWR.data?.result ? null : "要確認",
        },
      })
      const data = res.data as {
        main_examination_score: MainExaminationScore
      }
      latestMainExaminationScoreSWR.mutate(data.main_examination_score)
      SnackBarCard.fire({ title: "変更しました" })
    }
  }

  return (
    <StyledComponent
      actionTitle={actionTitle}
      latestProposal={latestProposal}
      actionCommonProps={actionCommonProps}
      initialGurusakuMessagesSWR={initialGurusakuMessagesSWR}
      user={selectedTalkRoomUser}
      mainExaminationScore={latestMainExaminationScoreSWR.data}
      handleMainExaminationResultChange={handleMainExaminationResultChange}
      latestMovingApplication={getAdoptedMovingApplication(latestProposal?.id)}
    />
  )
}

export const TalkRoomActions = Container
