import useSWR from "swr"
import { useSmoothApi } from "./useSmoothApi"
import { SMOOTH_SMOOTHER_API_URL } from "src/lib/constants"

export const useMzdaoStatus = (props: { userId: number }) => {
  const { getWithToken } = useSmoothApi()
  const mzdaoStatusSWR = useSWR(
    `${SMOOTH_SMOOTHER_API_URL}/users/${props.userId}/mzdao_status`,
    async (url) => {
      const res = await getWithToken(url)
      const data = (await res.data) as {
        is_mzdao_user: boolean
      }

      return {
        isMzdaoUser: data.is_mzdao_user,
      }
    }
  )

  return mzdaoStatusSWR
}
