import React, { useEffect, useMemo } from "react"
import styled from "styled-components"
import { useSelectedTalkRoom } from "../hooks/useSelectedTalkRoom"
import { SubmitArgs, useSubmitNotionUser } from "../hooks/useSubmitNotionUser"
import { SiNotion } from "react-icons/si"
import { generateClassName, Text } from "@smoothincorg/smooth-ui"
import { boxShadow } from "src/lib/styles/variable"
import { useMovingApplications } from "../hooks/useMovingApplications"

export type NotionCreateButtonProps = {
  id: number
  status: number
  first_name: string
  last_name: string
  first_furigana: string
  last_furigana: string
  store_id: number
  property_name: string
  moving_on: string
}

export const NOTION_STATUS = {
  LOADING: "LOADING",
  TRUE: "TRUE",
  FALSE: "FALSE",
} as const

export type NotionStatus = (typeof NOTION_STATUS)[keyof typeof NOTION_STATUS]

type Props = NotionCreateButtonProps & {
  handleSubmit: (args: SubmitArgs) => Promise<void>
  className?: string
  isSubmitting: boolean
  inNotion: NotionStatus
}

const { getRootClassName } = generateClassName("NotionCreateButton")

const Component: React.VFC<Props> = (props) => {
  const text = useMemo(() => {
    if (props.isSubmitting) {
      return "送信中..."
    }

    if (props.inNotion === NOTION_STATUS.LOADING) {
      return "Notionを確認中…"
    }

    if (props.inNotion === NOTION_STATUS.FALSE) {
      return "Notionにユーザー作成！"
    }

    if (props.inNotion === NOTION_STATUS.TRUE) {
      return "Notionに作成済でし！"
    }
  }, [props])

  const disabled = props.isSubmitting || props.inNotion === "TRUE" || props.inNotion === "LOADING"

  return (
    <button
      onClick={() =>
        props.handleSubmit({
          resource: {
            ...props,
          },
        })
      }
      className={getRootClassName(props)}
      disabled={disabled}
    >
      <SiNotion />
      <Text size={14}>{text}</Text>
    </button>
  )
}

const StyledComponent = styled(Component)`
  display: flex;
  align-items: center;
  border: 1px solid #000;
  padding: 4px 8px;
  border-radius: 6px;
  ${boxShadow.normal}

  svg {
    height: 100%;
    margin-right: 8px;
  }
`

const NotionCreateButtonContainer: React.VFC = () => {
  const { isSubmitting, handleSubmit, checkIsInNotion, inNotion } = useSubmitNotionUser()

  const { selectedTalkRoom, selectedTalkRoomUser } = useSelectedTalkRoom()

  const { getAdoptedMovingApplication } = useMovingApplications()

  const [last_furigana, first_furigana] = useMemo(
    () => selectedTalkRoom?.userFullNameFurigana.split(/\s+/) ?? [null, null],
    [selectedTalkRoom]
  )

  const adoptedMovingApplication = useMemo(() => {
    return getAdoptedMovingApplication(selectedTalkRoomUser?.latestProposalId)
  }, [getAdoptedMovingApplication, selectedTalkRoomUser?.latestProposalId])

  useEffect(() => {
    checkIsInNotion(selectedTalkRoomUser?.id)
  }, [checkIsInNotion, selectedTalkRoomUser?.id])

  if (!selectedTalkRoomUser) return null

  return (
    <StyledComponent
      store_id={selectedTalkRoomUser.storeId}
      moving_on={adoptedMovingApplication?.movingOn}
      property_name={adoptedMovingApplication?.houseName}
      id={selectedTalkRoomUser.id}
      status={selectedTalkRoomUser.status}
      first_name={selectedTalkRoomUser.firstName}
      last_name={selectedTalkRoomUser.lastName}
      first_furigana={first_furigana}
      last_furigana={last_furigana}
      inNotion={inNotion}
      handleSubmit={handleSubmit}
      isSubmitting={isSubmitting}
    />
  )
}

export const NotionCreateButton = NotionCreateButtonContainer
