import { useMemo } from "react"
import { useProposalsContractSWR } from "./useContractsSWR"
import { useNotionSaikenSWR } from "./useNotionSaikenSWR"

export const useIsMCI = (proposalId: number) => {
  const { data: contractData, isValidating } = useProposalsContractSWR(proposalId)
  const { data: notionSaikenData, isValidating: isNotionValidating } = useNotionSaikenSWR(
    contractData?.id
  )

  const mciNotionDocument = useMemo(() => {
    if (isValidating || isNotionValidating) return null
    if (contractData === null) return false

    const target = notionSaikenData?.results?.find((result) => {
      return result.properties?.contract_id?.number === contractData?.id
    })

    return target
  }, [contractData, isNotionValidating, isValidating, notionSaikenData?.results])

  return { mciNotionDocument }
}
