import { memo, useState } from "react"
import { useMemo } from "react"
import { TalkRoom as TalkRoomType, User } from "src/features/chat/types"
import { useChatRoomView } from "src/features/chat/hooks/useChatRoomView"
import { useSelectTalkRoom } from "src/features/chat/hooks/useSelectTalkRoom"
import { BsPersonCircle } from "react-icons/bs"
import styled from "styled-components"
import { color, font, generateClassName, Text } from "@smoothincorg/smooth-ui"
import { TalkHeaderStatusDisplay } from "./TalkHeaderStatusDisplay"
import dayjs from "dayjs"

type Props = {
  user: User
  talkRoom: TalkRoomType
  className?: string
  selectedUserId?: number
} & Pick<ReturnType<typeof useChatRoomView>, "openChatSection"> &
  Pick<ReturnType<typeof useSelectTalkRoom>, "selectTalkRoom">

const { getRootClassName, className } = generateClassName("TalkRoom")

// eslint-disable-next-line react/display-name
const Component = memo<Props>((props) => {
  const classNames = [
    getRootClassName(props),
    props.selectedUserId && props.talkRoom.userId === props.selectedUserId ? "selected" : "",
  ].join(" ")

  const [imageError, setImageError] = useState(false)

  const userFullNameFurigana = props.talkRoom.userFullNameFurigana
  const latestMessage = props.talkRoom.latestMessage

  const latestMessagePreview = useMemo(() => {
    if (!latestMessage) {
      return ""
    }

    if (latestMessage.type === "Message::Image") {
      return `${userFullNameFurigana}様が画像を送信しました`
    }

    if (latestMessage.type === "Message::Sticker" || latestMessage.content.type === "sticker") {
      return `${userFullNameFurigana}様がスタンプを送信しました。`
    }

    if (latestMessage.content.type === "video") {
      return `${userFullNameFurigana}様が動画を送信しました。`
    }

    return latestMessage.content?.text || ""
  }, [latestMessage, userFullNameFurigana])

  if (!props.user) return null

  return (
    <div
      data-chat-room-id={props.talkRoom?.id}
      className={classNames}
      onClick={() => {
        props.selectTalkRoom(props.talkRoom.userId)
        props.openChatSection()
      }}
    >
      <div className={`${className}__image`}>
        {props.user && !imageError ? (
          // eslint-disable-next-line @next/next/no-img-element
          <img
            src={props.user.avatarUrl}
            onError={() => {
              setImageError(true)
            }}
            alt={props.user.firstFurigana + props.user.lastFurigana + "様のプロフィール画像"}
          />
        ) : (
          <BsPersonCircle width={40} height={40} />
        )}
      </div>

      <div className="p-line-client__talk-room-text-section">
        <div className={`${className}__main-info`}>
          <Text size={14} weight="bold">
            {userFullNameFurigana}
          </Text>
          <Text size={12} color="darkGray">
            ID: {props.user.id}
          </Text>
          <Text size={12} color="darkGray" className="last-message">
            {typeof latestMessagePreview === "string"
              ? latestMessagePreview
              : latestMessagePreview?.altText}
          </Text>
        </div>
      </div>

      <div className={`${className}__meta`}>
        <TalkHeaderStatusDisplay talkRoom={props.talkRoom} />
        <Text size={8} color="darkGray">
          {dayjs(props.talkRoom.latestMessageSentAt).format("MM月DD日")}
        </Text>
      </div>
    </div>
  )
})

export const TalkRoom = styled(Component)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px;
  border-top: 2px solid ${color.lightGray};
  cursor: pointer;

  &.selected {
    background-color: ${color.lightGray};
  }

  .smooth-ui-TalkHeaderStatusDisplay {
    ${font(10)};
    margin-bottom: 10px;
  }

  .${className} {
    &__image {
      width: 40px;
      height: 40px;
      border-radius: 25px;
      overflow: hidden;
      margin-right: 10px;
      position: relative;
      z-index: 1;

      > img {
        display: block;
      }

      > svg {
        width: 100%;
        height: 100%;
      }
    }

    &__main-info {
      display: flex;
      flex-direction: column;

      > .last-message {
        width: 110px;
        overflow: hidden;
        text-overflow: 'emphasis';
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }

    &__meta {
      margin-left: auto;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
  }
`
