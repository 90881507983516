import React from "react"
import { generateClassName } from "src/lib/utils/generate-class-name"
import * as styles from "./Card.css"

type Props = JSX.IntrinsicElements["div"]

export const Card: React.FC<Props> = ({ children, className, ...props }) => {
  return (
    <div {...props} className={generateClassName("Card", [className, styles.root])}>
      {children}
    </div>
  )
}
