import React from "react"
import { useHandleMemoForm } from "src/features/chat/hooks/useHandleMemoForm"
import { useSmootherMemos } from "src/features/chat/hooks/useSmootherMemo"
import { color, font, generateClassName, Text, Title } from "@smoothincorg/smooth-ui"
import styled from "styled-components"
import { boxShadow } from "src/lib/styles/variable"
import { AiFillEdit, AiFillDelete } from "react-icons/ai"
import { MemoType } from "src/components/Memo"
import dayjs from "dayjs"
import { Smoother } from "src/features/auth/hooks/useCurrentSmoother"

type Props = {
  index: number
  memo: MemoType
  smoother: Smoother
  className?: string
} & Pick<
  ReturnType<typeof useSmootherMemos>,
  "expandMemo" | "foldMemo" | "memosRef" | "destroyMemo"
> &
  Pick<ReturnType<typeof useHandleMemoForm>, "openMemoForm">

const { getRootClassName, className } = generateClassName("Memo")

const Component: React.VFC<Props> = (props) => {
  const memo = props.memo
  const smoother = props.smoother

  if (!memo || !smoother) {
    return null
  }

  return (
    <div className={getRootClassName(props)} ref={props.memosRef}>
      <Title as="h4" size={16} weight="bold">
        {memo.title}
      </Title>

      <div className={`${className}__meta`}>
        <Text size={14}>{dayjs(memo.created_at).format("YYYY-MM-DD HH:mm")}</Text>
        <Text size={14}>作成者: {smoother.name || "不明"}</Text>
      </div>

      <div className={`${className}__body`}>{memo.body}</div>

      <div className="u-display-flex u-flex-align-items-center u-flex-justify-content-between">
        <div className={`${className}__actions`}>
          <AiFillEdit
            className="fas fa-edit u-mr-n u-text-color-dark-gray u-clickable"
            onClick={() => props.openMemoForm(props.memo)}
          ></AiFillEdit>
          <AiFillDelete
            className="fas fa-trash u-text-color-dark-gray u-clickable"
            onClick={() => props.destroyMemo(props.memo)}
          ></AiFillDelete>
        </div>
      </div>
    </div>
  )
}

export const Memo = styled(Component)`
  background-color: ${color.lightGray};
  padding: 4px 8px;
  border-radius: 4px;
  ${boxShadow.normal};

  .${className} {
    &__meta {
      display: flex;
      > * {
        margin-right: 6px;
      }
    }

    &__body {
      ${font(14)};
      padding: 4px 2px;
    }

    &__actions {
      svg {
        margin-right: 4px;
        width: 20px;
        height: 20px;
      }
    }
  }
`
