import produce from "immer"
import { useCallback } from "react"
import { useLineChatState } from "./useLineChatState"
import { useValueRef } from "src/hooks/useValueRef"
import { useSmoothApi } from "src/hooks/useSmoothApi"
import { SMOOTH_LINE_API_URL } from "src/lib/constants"
import { LineChatRes } from "../types"
import { camelCase } from "src/lib/aikagi"
import { useSelectedTalkRoom } from "./useSelectedTalkRoom"

export const useFetchTalkRooms = () => {
  const { lineChatState, setLineChatState } = useLineChatState()
  // const intervalRef = useRef(null)
  const stateRef = useValueRef(lineChatState)
  const { getWithToken } = useSmoothApi()
  const { selectedTalkRoom } = useSelectedTalkRoom()

  const buildSearchQuery = useCallback(() => {
    const state = stateRef.current
    const searchQuery = {}

    // 氏名・電話番号による絞り込み条件
    if (state.searchBoxValue) {
      Object.assign(searchQuery, {
        user_last_name_or_user_first_name_cont: state.searchBoxValue,
      })
    }

    // 担当者による絞り込み条件
    if (state.selectedSmootherId) {
      if (state.selectedSmootherId === "unassigned") {
        Object.assign(searchQuery, { smoothers_id_null: true })
      } else {
        Object.assign(searchQuery, {
          smoothers_id_eq: state.selectedSmootherId,
        })
      }
    }

    // ステータスによる絞り込み条件
    const statusValues = []
    // 督促済
    if (state.checkedStatuses.demanded) {
      statusValues.push(5)
    }
    // 未督促
    if (state.checkedStatuses.undemanding) {
      statusValues.push(15)
    }
    // 要対応
    if (state.checkedStatuses.action_required) {
      statusValues.push(20)
    }
    // 未返信
    if (state.checkedStatuses.replied) {
      statusValues.push(10)
    }
    // ステータス無し
    if (state.checkedStatuses.none) {
      statusValues.push(0)
    }
    if (statusValues.length > 0) {
      Object.assign(searchQuery, { status_eq_any: statusValues })
    }

    return searchQuery
  }, [stateRef])

  const fetchTalkRooms = useCallback(async () => {
    const state = stateRef.current
    if (state.fetchingTalkRooms) {
      return
    }

    setLineChatState(() => ({ ...state, fetchingTalkRooms: true }))

    const talkRoomsNum = state.talkRooms.length
    let params = talkRoomsNum >= 20 ? { limit: talkRoomsNum } : {}

    const searchQuery = buildSearchQuery()

    const searchQueryKeys = Object.keys(searchQuery)

    if (searchQueryKeys.length > 0) {
      const queryParams = { q: searchQuery }
      params = Object.assign(params, queryParams)
    }

    // 何かしらの検索条件（氏名/電話番号/担当者等）が設定されている場合、現在選択中のトークルームを含めない形でリストを取得する。
    // ただし、ステータスのみ設定されている場合はこの限りではない。
    if (
      searchQueryKeys.length === 1 &&
      searchQueryKeys.includes("status_eq_any") &&
      selectedTalkRoom
    ) {
      params = Object.assign(params, {
        user_id: selectedTalkRoom.userId,
      })
    }

    getWithToken(`${SMOOTH_LINE_API_URL}/base_api/smoother/chat_rooms`, {
      params: {
        ...params,
        q: JSON.stringify(searchQuery),
      },
    })
      .then((r: { data: { data: LineChatRes } }) => {
        const data = camelCase(r.data.data)
        const talkRooms = data.chatRooms
        const users = data.users
        const state = stateRef.current

        setLineChatState(() => {
          return {
            ...state,
            talkRooms,
            users,
            fetchingTalkRooms: false,
          }
        })
      })
      .catch((e) => {
        alert("トークルームの取得に失敗しました。")
        console.error(e)
      })
  }, [buildSearchQuery, getWithToken, setLineChatState, stateRef, selectedTalkRoom])

  const fetchTalkRoomsLooper = useCallback(() => {
    const state = stateRef.current
    const fetchTimeSpan = 120000 // 2分
    // 2分に1回、トークルームを最新の状態にする。
    // 手動更新に対応するため、一度clearTimeoutする。
    clearTimeout(state.timerIds.fetchTalkLooper)
    setLineChatState(() => {
      const timerIds = { ...state.timerIds }
      timerIds.fetchTalkLooper = setTimeout(() => {
        fetchTalkRooms()
        // ループ処理
        fetchTalkRoomsLooper()
      }, fetchTimeSpan) as unknown as NodeJS.Timeout
      return { ...state, timerIds: timerIds }
    })
  }, [fetchTalkRooms, setLineChatState, stateRef])

  const fetchMoreTalkRooms = useCallback(async () => {
    const state = stateRef.current

    if (state.fetchingTalkRooms) {
      return
    }

    setLineChatState(() => ({ ...state, fetchingTalkRooms: true }))

    const params = {
      q: {
        id_not_eq_all: state.talkRooms.map((talkRoom) => {
          return talkRoom.id
        }),
      },
    }

    const searchQuery = buildSearchQuery()

    if (Object.keys(searchQuery).length > 0) {
      params["q"] = Object.assign(params["q"], searchQuery)
    }

    const r: { data: { data: LineChatRes } } = await getWithToken(
      `${SMOOTH_LINE_API_URL}/base_api/smoother/chat_rooms`,
      {
        params: {
          ...params,
          q: JSON.stringify(params.q),
        },
      }
    ).catch((e) => {
      alert("トークルームの取得に失敗しました。")
      throw new Error(e)
    })

    const data = camelCase(r.data.data)

    const talkRooms = data.chatRooms
    const users = data.users
    const proposals = data.latestProposals

    setLineChatState(() => {
      const state = stateRef.current
      return produce(state, (draft) => {
        draft.talkRooms = [...(draft.talkRooms ?? []), ...talkRooms]
        draft.users = [...(draft.users ?? []), ...users]
        draft.latest_proposals = [...(draft.latest_proposals ?? []), ...proposals]
        draft.fetchingTalkRooms = false
      })
    })
  }, [buildSearchQuery, getWithToken, setLineChatState, stateRef])

  const startFetchTalkRoomsLooper = useCallback(() => {
    // if (intervalRef.current !== null) return
    // intervalRef.current = setInterval(() => {
    //   alert('テスト')
    //   fetchTalkRooms()
    // }, 10000)
  }, [])

  return {
    fetchTalkRooms,
    fetchTalkRoomsLooper,
    fetchMoreTalkRooms,
    startFetchTalkRoomsLooper,
  }
}
