import React, { useEffect } from "react"
import { SearchBox } from "src/features/chat/components/SearchBox"
import { useSearchBox } from "../hooks/useSearchBox"

export const SearchBoxContainer: React.VFC = () => {
  const { handleSearchBox, bindSearchBoxEvent } = useSearchBox()

  useEffect(() => {
    bindSearchBoxEvent()
  }, [bindSearchBoxEvent])

  return <SearchBox handleSearchBox={handleSearchBox} />
}
