import { useMemo } from "react"
import { useRecoilValue } from "recoil"
import { lineChatState } from "src/store/lineChatState"
import { useGetBaseData } from "./useGetBaseData"

export const useSelectedTalkRoom = () => {
  const state = useRecoilValue(lineChatState)
  const { getUser } = useGetBaseData()
  const selectedUserId = state.selectedUserId

  const selectedTalkRoom = useMemo(() => {
    if (!selectedUserId) {
      return null
    }

    const selectedTalkRoom = state.talkRooms?.find((talkRoom) => {
      return talkRoom.userId === selectedUserId
    })

    if (!selectedTalkRoom) {
      return null
    }

    return selectedTalkRoom
  }, [selectedUserId, state.talkRooms])

  const selectedTalkRoomUser = useMemo(() => {
    if (!selectedTalkRoom) return null
    return getUser(selectedTalkRoom?.userId)
  }, [getUser, selectedTalkRoom])

  return {
    selectedTalkRoom,
    selectedTalkRoomUser,
    selectedTalkRoomId: selectedTalkRoom?.id,
  }
}
