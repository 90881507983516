import { color, font, generateClassName, mq } from "@smoothincorg/smooth-ui"
import dayjs from "dayjs"
import React from "react"
import styled from "styled-components"
import { GetSmoother, User } from "src/features/chat/types"
import { CamelCase } from "src/lib/aikagi"
import { boxShadow } from "src/lib/styles/variable"
import { MessageContent } from "./MessageContent"

export type MessageRes = {
  chat_room_id: 192
  content:
    | {
        id: string
        text: string
        type: "text"
      }
    | {
        id: string
        type: "text"
        text: {
          type: "flex"
          altText: "チャットお部屋提案の希望条件を登録してください"
          contents: {
            hero: {
              url: string
              size: "full"
              type: "image"
              action: {
                uri: "https://liff.line.me/1653845526-0eGJGLyn/search-houses"
                type: "uri"
                label: "action"
              }
              aspectMode: "cover"
              aspectRatio: "10:10"
            }
            type: "bubble"
          }
        }
      }
    | {
        id: string
        text: string
        type: "image"
      }
    | {
        id: string
        type: "video"
        duration: number
        contentProvider: { type: "line" }
      }
    | {
        id: string
        type: "sticker"
        stickerId?: string
      }
    | {
        id: string
        type: "pdf"
        text: string
      }
  created_at: string
  enabled: true
  id: number
  image_data: null
  is_read: false
  messageable_id: number
  messageable_type: "User" | "Smoother"
  platform: "line"
  private_image_data: null | string
  updated_at: string
  type: string
  sticker_image_url?: string
}

export type MessageType = CamelCase<MessageRes>

type Props = {
  message: MessageType
  getSmoother: GetSmoother
  user: User
  className?: string
}

const { getRootClassName, className } = generateClassName("Message")

export const NO_AVATAR_PATH = `/images/no-avatar.png`

export const Component: React.FC<Props> = (props) => {
  const message = props.message

  if (message.messageableType === "Smoother") {
    const smoother = props.getSmoother(message.messageableId)

    return (
      <div
        data-id={props.message.id}
        className={getRootClassName(props) + ` ${className}--smoother`}
      >
        <div className={`${className}__meta`}>
          <div>{smoother && smoother.name}</div>
          <div className="">{dayjs(message.createdAt).format("YYYY年MM月DD日 HH:mm")}</div>
        </div>

        <MessageContent message={message} user={props.user} />
      </div>
    )
  }

  const user = props.user

  if (!message || !user) {
    return null
  }

  return (
    <div data-id={props.message.id} className={getRootClassName(props) + ` ${className}--user`}>
      {/* eslint-disable-next-line @next/next/no-img-element */}
      <img
        src={user.avatarUrl || NO_AVATAR_PATH}
        className={`${className}__avater`}
        onError={(e) => {
          if (e.target instanceof HTMLImageElement) {
            e.target.src = NO_AVATAR_PATH
          }
        }}
        alt="アバター"
      />

      <MessageContent message={message} user={user} />

      <div className={`${className}__meta`}>
        {dayjs(message.createdAt).format("YYYY年MM月DD日 HH:mm:ss")}
      </div>
    </div>
  )
}

const StyledComponent = styled(Component)`
  width: 100%;
  display: flex;

  .message-content {
    max-width: 530px;
    padding: 10px 16px;
    ${boxShadow.normal};
    border-radius: 9px;

    ${mq("sp_wide")} {
      max-width: 90%;
    }
  }

  &.${className} {
    &--user {
      justify-content: flex-start;

      .message-content {
        background-color: ${color.white};
        margin-left: 14px;
        &:after {
          content: '';
          display: inline-block;
          position: absolute;
          top: 3px;
          left: -19px;
          border: 8px solid transparent;
          border-right: 18px solid #ffffff;
          -webkit-transform: rotate(35deg);
          transform: rotate(35deg);
        }
      }
    }

    &--smoother {
      margin-left: auto;
      justify-content: flex-end;
      text-align: right;

      .message-content {
        background: ${color.gradationBlue};
        color: #ffffff;
        text-align: left;

        > a {
          color: ${color.white};
        }
      }
    }

    &--user,
    &--smoother {
      .message-content {
        &.sticker,
        &.image {
          margin-left: 0px;
          box-shadow: none;
          background: transparent;
          &::after {
            display: none;
          }
        }
      }
    }
  }

  .${className} {
    &__avater {
      width: 40px;
      height: 40px;
      border-radius: 25px;

      > svg {
        width: 40px;
        height: 100%;
      }
    }

    &__meta {
      margin-top: auto;
      margin-right: 6px;
      margin-left: 6px;
      ${font(4)};
    }
  }
`

export const Message = StyledComponent
