import { generateClassName } from "@smoothincorg/smooth-ui"
import styled from "styled-components"
import { MemoType } from "src/components/Memo"
import { MemoFormContainer } from "src/features/chat/containers/MemoFormContainer"
import { useGetBaseData } from "src/features/chat/hooks/useGetBaseData"
import { useHandleMemoForm } from "src/features/chat/hooks/useHandleMemoForm"
import { useSmootherMemos } from "src/features/chat/hooks/useSmootherMemo"
import { Memo } from "./Memo"

const { getRootClassName } = generateClassName("Memos")

type Props = {
  memos: MemoType[]
  className?: string
} & Pick<ReturnType<typeof useGetBaseData>, "getSmoother"> &
  Pick<
    ReturnType<typeof useSmootherMemos>,
    "memosRef" | "destroyMemo" | "expandMemo" | "foldMemo" | "saveMemo"
  > &
  Pick<ReturnType<typeof useHandleMemoForm>, "memoFormOpened" | "openMemoForm">

const Component: React.FC<Props> = (props) => {
  if (!props.memos) return <div className=""></div>
  return (
    <div className={getRootClassName(props)}>
      <div className="p-line-client__memos">
        {props.memos.filter(Boolean).map((memo, i) => {
          const smoother = props.getSmoother(memo.memoable_id)

          return (
            <Memo
              key={memo.id}
              // States
              index={i}
              memo={memo}
              smoother={smoother}
              // References
              memosRef={props.memosRef}
              // Functions
              destroyMemo={props.destroyMemo}
              expandMemo={props.expandMemo}
              foldMemo={props.foldMemo}
              openMemoForm={props.openMemoForm}
            />
          )
        })}
      </div>
      {props.memoFormOpened && <MemoFormContainer />}
    </div>
  )
}

export const Memos = styled(Component)`
  .smooth-ui-Memo {
    margin-bottom: 10px;
    margin-top: 10px;
  }
`
