import React, { useState } from "react"
import { TemplateMessages } from "./TemplateMessages"
import { useMessageForm } from "../hooks/useMessageForm"
import { color, generateClassName, mq } from "@smoothincorg/smooth-ui"
import { IoMdPhotos, IoMdSend } from "react-icons/io"
import { HiTemplate } from "react-icons/hi"
import { useLineChatState } from "../hooks/useLineChatState"
import { Result } from "src/lib/notion/api-types"
import { useRouter } from "next/router"
import { V2TemplateMessages } from "./V2TemplateMessages"
import styled from "styled-components"

export type MessageFormProps = {
  sendTextMessage: React.FormEventHandler
  sendImageMessage: React.ChangeEventHandler<HTMLInputElement>
  newMessage: string
  messageFormTextareaRef: React.MutableRefObject<HTMLTextAreaElement>
  handleMessageForm: React.ChangeEventHandler<HTMLTextAreaElement>
  templateMessages: Result[] | Error
  userId: number
} & Pick<ReturnType<typeof useMessageForm>, "appendMessage">

type Props = {
  className?: string
  displayTemplateOnInit: boolean
} & MessageFormProps

const { className, getRootClassName } = generateClassName("MessageForm")

const Component: React.FC<Props> = (props) => {
  const [selectedTemplate, setSelectedTemplate] = useState<"v1" | "v2" | null>(
    props.displayTemplateOnInit ? "v1" : null
  )

  return (
    <div className={`${getRootClassName(props)}`} id="messages_form">
      <form onSubmit={props.sendTextMessage} className={`${className}__form`}>
        <button
          type="button"
          onClick={() =>
            setSelectedTemplate(() => {
              if (selectedTemplate === null) {
                return "v1"
              }
              if (selectedTemplate === "v1") {
                return "v2"
              }

              if (selectedTemplate === "v2") {
                return null
              }
            })
          }
        >
          <span className={`${className}__template-icon`}>
            <HiTemplate />
            {selectedTemplate && (
              <span className={`${className}__template-name`}>{selectedTemplate}</span>
            )}
          </span>
        </button>
        <label className={`${className}__file-uploader`} htmlFor="file_uploader">
          <input onChange={props.sendImageMessage} type="file" id="file_uploader" />
          <IoMdPhotos />
        </label>

        <textarea
          // HTML Settings
          value={props.newMessage}
          placeholder="ここに入力してください。"
          className={`${props.className}-textarea`}
          // React Settings
          ref={props.messageFormTextareaRef}
          onChange={props.handleMessageForm}
          style={{
            height: 15 * 1.25 * (props.newMessage.match(/\n/g)?.length || 1) + 40,
          }}
        />

        <button type="submit">
          <IoMdSend />
        </button>
      </form>
      {props.templateMessages instanceof Error ? (
        <div className="">{`エラー：${props.templateMessages}`}</div>
      ) : (
        <div className={`${className}__actions`}>
          {props.templateMessages && (
            <div className="">
              {selectedTemplate === "v1" && (
                <div className="v1">
                  <TemplateMessages
                    templateMessages={props.templateMessages}
                    appendMessage={props.appendMessage}
                  />
                </div>
              )}
              {selectedTemplate === "v2" && (
                <div className="v2">
                  <V2TemplateMessages userId={props.userId} appendMessage={props.appendMessage} />
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  )
}

const StyledComponent = styled(Component)`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: #ffffff;
  z-index: 3;

  ${mq("sp_wide")} {
    position: fixed;
    bottom: 0;
    width: 100%;
  }

  .${className} {
    &__form {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      padding: 20px 0;

      > button,
      > label {
        margin: 0 6px;
        width: 30px;
        height: 30px;
        fill: ${color.darkBlue};

        svg {
          width: 100%;
          height: 100%;
        }
      }
    }

    &__file-uploader {
      cursor: pointer;
      > input {
        display: none;
      }
    }

    &__actions {
      width: 100%;
      padding: 8px 20px;
      padding-top: 0;

      .smooth-ui-Title {
        display: flex;
        align-items: center;
        margin-bottom: 8px;
      }

      .smooth-ui-TemplateMessages {
        border-top: 1px solid ${color.lightGray};
        width: 100%;
        max-height: 400px;
      }
    }

    &__template-icon {
      display: block;
      position: relative;
      width: 30px;
      height: 30px;
    }

    &__template-name {
      position: absolute;
      top: -6px;
      right: -6px;
      font-size: 1.2rem;
      color: black;
      background-color: white;
      /* text-shadow: 0 0 10px white; */
      box-shadow: 0 0 10px black;
      font-weight: bold;
      padding: 2px 4px;
      border-radius: 4px;
    }

    &__actions-nav {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 8px;

      > button {
        padding: 4px 8px;
        border-radius: 4px;
        background-color: ${color.white};
        cursor: pointer;
        font-size: 1.6rem;

        &.selected {
          background-color: ${color.mediumGray};
          color: ${color.white};
        }
      }
    }
  }

  &-textarea {
    font-size: 15px;
    line-height: 125%;
    padding: 20px;
    border: 1px solid #000;
    width: 80%;
    border-radius: 25px;
    max-height: 250px;
  }
`

type ContainerProps = {
  userId: number
  templateMessages: Result[] | Error
}

export const MessageForm: React.VFC<ContainerProps> = ({ templateMessages, ...props }) => {
  const { lineChatState } = useLineChatState()
  const router = useRouter()
  const displayTemplateOnInit = router.query["display_template_messages"] !== "false"

  const {
    handleMessageForm,
    messageFormTextareaRef,
    sendTextMessage,
    sendImageMessage,
    appendMessage,
  } = useMessageForm()

  const isReady = router.isReady

  return isReady ? (
    <StyledComponent
      userId={props.userId}
      // States
      newMessage={lineChatState.newMessage}
      // References
      messageFormTextareaRef={messageFormTextareaRef}
      // Functions
      appendMessage={appendMessage}
      sendTextMessage={sendTextMessage}
      sendImageMessage={sendImageMessage}
      handleMessageForm={handleMessageForm}
      templateMessages={templateMessages}
      displayTemplateOnInit={displayTemplateOnInit}
    />
  ) : null
}
