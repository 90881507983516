import { getLatest } from "src/lib/utils/model-utils"
import { useLineChatState } from "./useLineChatState"

export const useMovingApplications = () => {
  const { lineChatState } = useLineChatState()

  // def adopted_moving_application
  //   moving_applications.enabled.adopted.order('updated_at DESC').first
  // end

  const getAdoptedMovingApplication = (proposalId: number) => {
    const movingApplications = lineChatState.latest_proposals?.find(
      (p) => p.id === proposalId
    )?.movingApplications

    const enabledMovingApplications = movingApplications?.filter(() => true)

    const latest = getLatest(enabledMovingApplications)

    return latest
  }

  return {
    getAdoptedMovingApplication,
  }
}
