import { generateClassName } from "@smoothincorg/smooth-ui"
import React from "react"
import { useMessageForm } from "../hooks/useMessageForm"
import styled from "styled-components"
import { BiSend } from "react-icons/bi"
import { useSmoothApi } from "src/hooks/useSmoothApi"
import { SMOOTH_SMOOTHER_API_URL } from "src/lib/constants"
import {
  v2TemplateMessages,
  V2TemplateMessagesType,
} from "src/features/chat/constants/messageTemplate"

type Props = {
  className?: string
  userId: number
} & Pick<ReturnType<typeof useMessageForm>, "appendMessage">

const { getRootClassName } = generateClassName("V2TemplateMessages")

const MessageWrapper = styled.div`
  margin: 2rem 0;
  border-radius: 0.5rem;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.07);
`

const Component: React.FC<Props> = (props) => {
  const { postWithToken } = useSmoothApi()

  function renderMessage(message: V2TemplateMessagesType) {
    if (message.type === "text") {
      return <MessageWrapper key={message.text}>{message.text}</MessageWrapper>
    } else if (message.type === "imagemap") {
      return (
        <MessageWrapper key={`${message.baseUrl}1040`}>
          <img
            src={`${message.baseUrl}1040`}
            style={{
              width: "100%",
              maxWidth: 200,
              height: "auto%",
            }}
            alt={message.altText}
          />
        </MessageWrapper>
      )
    }
    return (
      <MessageWrapper key={message.contents.hero.url}>
        {/* eslint-disable-next-line @next/next/no-img-element */}
        <img
          src={message.contents.hero.url}
          style={{
            width: "100%",
            maxWidth: 200,
            height: "auto%",
          }}
          alt={message.altText}
        />
      </MessageWrapper>
    )
  }

  return (
    <div className={getRootClassName(props)}>
      {v2TemplateMessages.map((message) => {
        return (
          <div key={message.title} className={`${props.className}__item`}>
            <span>
              <h3>{message.title}</h3>
              <span>{renderMessage(message)}</span>
            </span>
            <button
              type="button"
              onClick={async () => {
                const confirmed = confirm("送信しますか？")

                if (confirmed) {
                  try {
                    const res = await postWithToken(`${SMOOTH_SMOOTHER_API_URL}/line_message`, {
                      messages: [message],
                      user_ids: [props.userId],
                    })

                    if (res.status !== 200) {
                      alert("送信に失敗しました")
                    }

                    if (res.data?.["message"]) {
                      alert(res?.data["message"])
                      window.location.reload()
                    }
                  } catch (e) {
                    alert("送信に失敗しました")
                  }
                }
              }}
            >
              <BiSend
                style={{
                  width: "20px",
                  height: "20px",
                }}
              />
            </button>
          </div>
        )
      })}
    </div>
  )
}

const StyledComponent = styled(Component)`
  height: 400px;
  overflow-y: scroll;

  &__item {
    padding: 20px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    background-color: #fff;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
  }
`

export const V2TemplateMessages = StyledComponent
