import produce from "immer"
import { useCallback, useMemo } from "react"
import { useRecoilState } from "recoil"
import { useSmoothApi } from "src/hooks/useSmoothApi"
import { SMOOTH_LINE_API_URL } from "src/lib/constants"
import { LineChatState, lineChatState } from "src/store/lineChatState"
import { useFetchTalkRooms } from "./useFetchTalkRooms"
import { useSelectedTalkRoom } from "./useSelectedTalkRoom"

export const useToggleStatusFilter = () => {
  const [state, setState] = useRecoilState(lineChatState)
  const { fetchTalkRooms } = useFetchTalkRooms()
  const { selectedTalkRoom } = useSelectedTalkRoom()
  const { patchWithToken } = useSmoothApi()

  const toggleStatusFilterCheckbox = useCallback(
    (status: keyof LineChatState["checkedStatuses"]) => {
      setState((prevState) =>
        produce(prevState, (draft) => {
          draft.checkedStatuses[status] = !draft.checkedStatuses[status]
        })
      )

      // [TODO] move to useEffect??
      setTimeout(() => {
        fetchTalkRooms()
      }, 100)
    },
    [fetchTalkRooms, setState]
  )

  const toggleStatusFilterDropdown = useCallback(() => {
    setState((prevState) => {
      return {
        ...prevState,
        statusFilterDropdownOpened: !prevState.statusFilterDropdownOpened,
      }
    })
  }, [setState])

  const toggleDemandedStatus = useCallback(async () => {
    if (!selectedTalkRoom) {
      alert("トークルームが選択されていません。")
      return
    }

    if (selectedTalkRoom.statusJa === "督促済") {
      if (!confirm("「督促済」ステータスを解除しますか？")) {
        return
      }
    } else {
      if (!confirm("「督促済」ステータスに設定しますか？")) {
        return
      }
    }

    const requestPath = SMOOTH_LINE_API_URL + selectedTalkRoom.toggleDemandedStatusPath

    await patchWithToken(requestPath).catch((err) => {
      alert("ステータスの更新に失敗しました。")
      throw new Error(err)
    })

    await fetchTalkRooms()
  }, [fetchTalkRooms, patchWithToken, selectedTalkRoom])

  const toggleUndemandingStatus = useCallback(async () => {
    if (!selectedTalkRoom) {
      alert("トークルームが選択されていません。")
      return
    }

    if (selectedTalkRoom.statusJa === "未督促") {
      if (!confirm("「未督促」ステータスを解除しますか？")) {
        return
      }
    } else {
      if (!confirm("「未督促」ステータスに設定しますか？")) {
        return
      }
    }

    const requestPath = SMOOTH_LINE_API_URL + selectedTalkRoom.toggleUndemandingStatusPath

    await patchWithToken(requestPath).catch((err) => {
      alert("ステータスの更新に失敗しました。")
      throw new Error(err)
    })

    await fetchTalkRooms()
  }, [fetchTalkRooms, patchWithToken, selectedTalkRoom])

  const toggleActionRequiredStatus = useCallback(async () => {
    if (!selectedTalkRoom) {
      alert("トークルームが選択されていません。")
      return
    }

    if (selectedTalkRoom.statusJa === "要対応") {
      if (!confirm("「要対応」ステータスを解除しますか？")) {
        return
      }
    } else {
      if (!confirm("「要対応」ステータスに設定しますか？")) {
        return
      }
    }

    const requestPath = SMOOTH_LINE_API_URL + selectedTalkRoom.toggleActionRequiredStatusPath

    await patchWithToken(requestPath).catch((err) => {
      alert("ステータスの更新に失敗しました。")
      throw new Error(err)
    })

    await fetchTalkRooms()
  }, [fetchTalkRooms, patchWithToken, selectedTalkRoom])

  const toggleRepliedStatus = useCallback(async () => {
    if (!selectedTalkRoom) {
      alert("トークルームが選択されていません。")
      return
    }

    if (selectedTalkRoom.statusJa === "返信済") {
      if (!confirm("「返信済」ステータスを解除しますか？")) {
        return
      }
    } else {
      if (!confirm("「返信済」ステータスに設定しますか？")) {
        return
      }
    }

    const requestPath = SMOOTH_LINE_API_URL + selectedTalkRoom.toggleRepliedStatusPath

    try {
      await patchWithToken(requestPath)
    } catch (e) {
      alert("失敗しました")
      return
    }

    await fetchTalkRooms()
  }, [fetchTalkRooms, patchWithToken, selectedTalkRoom])

  const toggleStatus = useCallback(
    async (statusJa: string) => {
      if (!selectedTalkRoom) {
        alert("トークルームが選択されていません。")
        return
      }

      switch (statusJa) {
        case "督促済":
          await toggleDemandedStatus()
          break
        case "未督促":
          await toggleUndemandingStatus()
          break
        case "要対応":
          await toggleActionRequiredStatus()
          break
        case "返信済":
          toggleRepliedStatus()
          break
        default:
          alert("存在しないステータスです。")
      }
    },
    [
      selectedTalkRoom,
      toggleDemandedStatus,
      toggleUndemandingStatus,
      toggleActionRequiredStatus,
      toggleRepliedStatus,
    ]
  )

  const statusFilterDropdownOpened = useMemo(() => {
    return state.statusFilterDropdownOpened
  }, [state.statusFilterDropdownOpened])

  const checkedStatuses = useMemo(() => {
    return state.checkedStatuses
  }, [state.checkedStatuses])

  return {
    checkedStatuses,
    statusFilterDropdownOpened,
    toggleStatusFilterCheckbox,
    toggleStatusFilterDropdown,
    toggleStatus,
  }
}
