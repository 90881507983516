import { useFetchTalkRooms } from "./useFetchTalkRooms"
import { useSetRecoilState } from "recoil"
import { lineChatState } from "src/store/lineChatState"
import { useCallback } from "react"

export const useRefreshTalkRooms = () => {
  const setState = useSetRecoilState(lineChatState)
  const { fetchTalkRooms } = useFetchTalkRooms()

  const refreshTalkRooms = useCallback(() => {
    setState((prevState) => {
      return { ...prevState, talkRooms: [] }
    })

    setTimeout(() => {
      fetchTalkRooms()
      // fetchTalkRoomsLooper()
    }, 100)
  }, [fetchTalkRooms, setState])

  return {
    refreshTalkRooms,
  }
}
