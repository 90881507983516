import { useCallback, useRef } from "react"
import { useFetchMessages } from "./useFetchMessages"
import { useFetchTalkRooms } from "./useFetchTalkRooms"
import { useLineChatState } from "./useLineChatState"

export const useChatRoomView = () => {
  const talkRoomsContainerRef = useRef<HTMLDivElement>()
  const { fetchPreviousMessages } = useFetchMessages()
  const { fetchMoreTalkRooms } = useFetchTalkRooms()
  const initializedRef = useRef(false)
  const { lineChatState, setLineChatState } = useLineChatState()

  const isPcView = true

  const openChatSection = useCallback(() => {
    return console.log("PC画面なので何も処理しません。")
  }, [isPcView, setLineChatState])

  const openTalkRoomInfoSection = () => {
    if (isPcView) {
      return console.log("PC画面なので何も処理しません。")
    }

    setLineChatState((prevState) => {
      return {
        ...prevState,
        displayedSections: {
          assigneesSection: false,
          talkRoomsSection: false,
          chatSection: false,
          talkRoomInfoSection: true,
        },
      }
    })
  }

  const openTalkRoomsSection = () => {
    if (isPcView) {
      return console.log("PC画面なので何も処理しません。")
    }

    setLineChatState((prevState) => {
      return {
        ...prevState,
        displayedSections: {
          assigneesSection: false,
          talkRoomsSection: true,
          chatSection: false,
          talkRoomInfoSection: false,
        },
      }
    })
  }

  const initializeChatRoomView = useCallback(() => {
    if (initializedRef.current) return
    initializedRef.current = true

    const displayedSections = {
      assigneesSection: isPcView,
      talkRoomsSection: isPcView,
      chatSection: true,
      talkRoomInfoSection: isPcView,
    }

    const isKey = (key: string): key is keyof typeof displayedSections =>
      Object.getOwnPropertyNames(displayedSections).some((n) => n === key)

    const shouldDispatch = Object.entries(displayedSections).some(([key, value]) => {
      if (isKey(key) && lineChatState.displayedSections[key] !== value) return true
    })

    if (shouldDispatch) {
      setLineChatState((prevState) => {
        return {
          ...prevState,
          displayedSections: {
            assigneesSection: isPcView,
            talkRoomsSection: isPcView,
            chatSection: true,
            talkRoomInfoSection: isPcView,
          },
        }
      })
    }
  }, [isPcView, setLineChatState, lineChatState.displayedSections])

  const talkScrollCallback = useCallback(() => {
    const shouldFetchPreviousMessages = () => {
      const messagesContainer = document.getElementById("messages_container")
      if (!messagesContainer) return false
      // スクロール要素のflex-directionがcolumn-reverseなので、scrollTopで取得できる値がマイナスになる。計算がおかしくなるので、絶対値を用いて計算する。
      // スクロール可能領域 - (表示領域 + スクロール量) <= 0なら、最下部までスクロールしたと判定。
      const scrolledToBottom =
        messagesContainer.scrollHeight -
          (messagesContainer.clientHeight + Math.ceil(Math.abs(messagesContainer.scrollTop))) <=
        0
      return (
        scrolledToBottom &&
        lineChatState.displayedSections["chatSection"] &&
        lineChatState.messages.length >= 20
      )
    }

    const shouldFetchMoreTalkRooms = () => {
      const talkRoomsContainer = talkRoomsContainerRef.current

      if (!talkRoomsContainer) return
      // スクロール可能領域 - (表示領域 + スクロール量) <= 0なら、最下部までスクロールしたと判定。
      const scrolledToBottom =
        talkRoomsContainer.scrollHeight -
          (talkRoomsContainer.clientHeight + Math.ceil(Math.abs(talkRoomsContainer.scrollTop))) <=
        0

      return (
        scrolledToBottom &&
        lineChatState.displayedSections["talkRoomsSection"] &&
        lineChatState.talkRooms.length >= 20
      )
    }

    if (shouldFetchPreviousMessages()) {
      fetchPreviousMessages()
    }

    if (shouldFetchMoreTalkRooms()) {
      fetchMoreTalkRooms()
    }
  }, [
    fetchMoreTalkRooms,
    // [FIXME]これのせいで再レンダー
    fetchPreviousMessages,
    lineChatState.displayedSections,
    lineChatState.messages.length,
    lineChatState.talkRooms.length,
  ])

  const chatScrollCallback = useCallback(() => {
    const shouldFetchPreviousMessages = () => {
      const messagesContainer = document.getElementById("messages_container")
      if (!messagesContainer) return false
      // スクロール要素のflex-directionがcolumn-reverseなので、scrollTopで取得できる値がマイナスになる。計算がおかしくなるので、絶対値を用いて計算する。
      // スクロール可能領域 - (表示領域 + スクロール量) <= 0なら、最下部までスクロールしたと判定。
      const scrolledToBottom =
        messagesContainer.scrollHeight -
          (messagesContainer.clientHeight + Math.ceil(Math.abs(messagesContainer.scrollTop))) <=
        10

      return (
        scrolledToBottom &&
        lineChatState.displayedSections["chatSection"] &&
        lineChatState.messages.length >= 20
      )
    }
    if (shouldFetchPreviousMessages()) {
      fetchPreviousMessages()
    }
  }, [fetchPreviousMessages, lineChatState.displayedSections, lineChatState.messages.length])

  return {
    isPcView,
    initializeChatRoomView,
    openTalkRoomInfoSection,
    openChatSection,
    openTalkRoomsSection,
    talkScrollCallback,
    talkRoomsContainerRef,
    chatScrollCallback,
  }
}
