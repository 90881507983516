import React, { useCallback } from "react"
import copy from "copy-to-clipboard"

type Props = {
  text: string
  children: JSX.Element
  onCopy?: (text?: string, result?: boolean) => void
  options?: {
    debug?: boolean
    message?: string
    format?: string
  }
}

export const CopyToClipboard: React.FC<Props> = (props) => {
  const onClick = useCallback(
    (event: React.MouseEvent) => {
      const { text, onCopy, children, options } = props

      const elem = React.Children.only(children)

      const result = copy(text, options)

      if (onCopy) {
        onCopy(text, result)
      }

      // Bypass onClick if it was present
      if (elem.props && typeof elem.props.onClick === "function") {
        elem.props.onClick(event)
      }
    },
    [props]
  )

  const elem = React.Children.only(props.children)

  if (["string", "number"].includes(typeof elem)) {
    return
  }

  return <button onClick={onClick}>{props.children}</button>
}
