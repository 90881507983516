import { useCallback, useState } from "react"
import { useLineChatState } from "./useLineChatState"
import produce from "immer"
import { useSmoothApi } from "src/hooks/useSmoothApi"
import { MemoType } from "src/components/Memo"
import { SMOOTH_SMOOTHER_API_URL } from "src/lib/constants/value"

export const useFetchMemos = () => {
  const { setLineChatState } = useLineChatState()
  const { getWithToken } = useSmoothApi()
  const [fetching, setFetching] = useState(false)

  const fetchMemos = useCallback(
    async (userId: number) => {
      if (fetching) return
      setFetching(true)
      const res = await getWithToken(
        SMOOTH_SMOOTHER_API_URL + "/deserialized_memos" + "?user_id=" + userId
      ).catch((error) => {
        alert("メモの取得に失敗しました。")
        throw new Error(error)
      })

      const memos = res.data["data"] as MemoType[]

      setLineChatState((prevState) => {
        return produce(prevState, (draft) => {
          draft.memos = memos
        })
      })

      setFetching(false)
    },
    [fetching, getWithToken, setLineChatState]
  )

  return {
    fetchMemos,
  }
}
