import useSWR from "swr"
import { SMOOTH_SMOOTHER_API_URL } from "src/lib/constants/value"
import { useSmoothApi } from "../useSmoothApi"

// export type MainExaminationScore = {
//   cic_examination: null
//   company_antisocial_examination: null
//   company_bankruptcy_examination: null
//   company_fact_examination: null
//   conflict_examination: null
//   created_at: '2022-02-25T17:55:29.000+09:00'
//   enabled: true
//   id: 7
//   lendable_loan: null
//   main_examination_id: 7
//   reason_by_smooth: null
//   reason_by_store: null
//   result: null
//   smooth_examination: null
//   updated_at: '2022-02-25T17:55:29.000+09:00'
//   user_antisocial_examination: null
//   user_bankruptcy_examination: null
//   moving_application_id: number
// }

export type MainExaminationScore = {
  cic_examination: string
  company_antisocial_examination: string
  company_bankruptcy_examination: string
  company_fact_examination: string
  conflict_examination: string
  created_at: string
  enabled: boolean
  id: number
  lendable_loan: number
  main_examination_id: number
  reason_by_smooth: string
  reason_by_store: string
  result: string
  smooth_examination: string
  updated_at: string
  user_antisocial_examination: string
  user_bankruptcy_examination: string
  moving_application_id: number
}

export const useLatestMainExaminationsSWR = (proposalId: number) => {
  const { getWithToken } = useSmoothApi()
  const latestMainExaminationScoreUrl = `${SMOOTH_SMOOTHER_API_URL}/proposals/${proposalId}/latest_main_examination_score`
  const latestMainExaminationScoreSWR = useSWR<MainExaminationScore>(
    proposalId ? latestMainExaminationScoreUrl : null,
    async () => {
      const res = await getWithToken(latestMainExaminationScoreUrl)
      const { data } = res.data as { data: MainExaminationScore }
      return data
    }
  )

  return {
    latestMainExaminationScoreSWR,
  }
}
