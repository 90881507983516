import { generateClassName } from "@smoothincorg/smooth-ui"
import React from "react"
import styled from "styled-components"
import { useToggleStatusFilter } from "src/features/chat/hooks/useToggleStatusFilter"
import { boxShadow } from "src/lib/styles/variable"
import { StatusFilterOption, STATUS_FILTER_OPTION_STATUSES } from "./StatusFilterOption"

type Props = Pick<
  ReturnType<typeof useToggleStatusFilter>,
  "statusFilterDropdownOpened" | "toggleStatusFilterCheckbox" | "checkedStatuses"
> & {
  className?: string
}

const { getRootClassName } = generateClassName("StatusFilterDropdown")

const Component: React.VFC<Props> = (props) => {
  const classNames = [
    getRootClassName(props),
    props.statusFilterDropdownOpened === true ? "opened" : "",
  ].join(" ")

  return (
    <div className={classNames}>
      <StatusFilterOption
        // States
        checkedStatuses={props.checkedStatuses}
        status={STATUS_FILTER_OPTION_STATUSES.DEMANDED}
        // Functions
        toggleStatusFilterCheckbox={props.toggleStatusFilterCheckbox}
      />
      <StatusFilterOption
        // States
        checkedStatuses={props.checkedStatuses}
        status={STATUS_FILTER_OPTION_STATUSES.UNDEMANDING}
        // Functions
        toggleStatusFilterCheckbox={props.toggleStatusFilterCheckbox}
      />
      <StatusFilterOption
        // States
        checkedStatuses={props.checkedStatuses}
        status={STATUS_FILTER_OPTION_STATUSES.ACTION_REQUIRED}
        // Functions
        toggleStatusFilterCheckbox={props.toggleStatusFilterCheckbox}
      />
      <StatusFilterOption
        // States
        checkedStatuses={props.checkedStatuses}
        status={STATUS_FILTER_OPTION_STATUSES.REPLIED}
        // Functions
        toggleStatusFilterCheckbox={props.toggleStatusFilterCheckbox}
      />
      <StatusFilterOption
        // States
        checkedStatuses={props.checkedStatuses}
        status={STATUS_FILTER_OPTION_STATUSES.NONE}
        // Functions
        toggleStatusFilterCheckbox={props.toggleStatusFilterCheckbox}
      />
    </div>
  )
}

const StyledComponent = styled(Component)`
  position: absolute;
  background: #ffffff;
  top: 56px;
  width: 100%;
  ${boxShadow.normal};
  display: none;
  z-index: 2;

  &.opened {
    display: block;
  }
`

const Container: React.VFC = () => {
  const { statusFilterDropdownOpened, toggleStatusFilterCheckbox, checkedStatuses } =
    useToggleStatusFilter()

  return (
    <StyledComponent
      statusFilterDropdownOpened={statusFilterDropdownOpened}
      toggleStatusFilterCheckbox={toggleStatusFilterCheckbox}
      checkedStatuses={checkedStatuses}
    />
  )
}

export const StatusFilterDropdown = Container
