import { useRouter } from "next/router"
import { useCallback } from "react"
import { useLineChatState } from "./useLineChatState"

export const useSelectTalkRoom = () => {
  const router = useRouter()
  const { setLineChatState } = useLineChatState()

  const selectTalkRoom = useCallback(
    (userId: number) => {
      setLineChatState((state) => ({
        ...state,
        selectedUserId: userId,
      }))
      window.history.pushState({}, "", `/admin/chat?selected_in_chat_user_id=${userId}`)
    },
    [setLineChatState]
  )

  return {
    selectTalkRoom,
  }
}
