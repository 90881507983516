import React, { ChangeEvent, MouseEvent, useCallback, useRef } from "react"
import { useRecoilState } from "recoil"
import { useSmoothApi } from "src/hooks/useSmoothApi"
import { camelCase } from "src/lib/aikagi"
import { SMOOTH_LINE_API_URL } from "src/lib/constants"
import { lineChatState } from "src/store/lineChatState"
import { MessageType } from "../components/Message"
import { useFetchTalkRooms } from "./useFetchTalkRooms"
import { useSelectedTalkRoom } from "./useSelectedTalkRoom"

export const useMessageForm = () => {
  const [state, setState] = useRecoilState(lineChatState)
  const { selectedTalkRoom } = useSelectedTalkRoom()
  const { fetchTalkRooms } = useFetchTalkRooms()
  const messageFormTextareaRef = useRef<HTMLTextAreaElement>()
  const { postWithToken } = useSmoothApi()

  const handleMessageForm = (e: ChangeEvent<HTMLTextAreaElement>) => {
    e.persist()

    setState((prevState) => {
      return { ...prevState, newMessage: e.target.value }
    })
  }

  const resetMessageForm = useCallback(() => {
    const messageFormTextarea = messageFormTextareaRef.current

    if (!messageFormTextarea) {
      return
    }

    setState((prevState) => {
      return { ...prevState, newMessage: "" }
    })
    messageFormTextarea.style.height = messageFormTextarea.style.minHeight
  }, [setState])

  const appendMessage = useCallback(
    (val: string) => {
      setState((prevState) => {
        return { ...prevState, newMessage: prevState.newMessage + val }
      })
    },
    [setState]
  )

  const sendTextMessage = useCallback(
    async (e: MouseEvent) => {
      e.preventDefault()

      if (!selectedTalkRoom) {
        alert("トークルームが選択されていません。")
        return
      }

      // Validations
      if (state.newMessage === "") {
        alert("メッセージを入力してください。")
        return
      }
      if (state.newMessage.length > 2000) {
        alert("文字数は2,000字に以内に収めてください")
        return
      }

      const requestPath = selectedTalkRoom.postMessagePath
      const params = { message: { text: state.newMessage } }

      const res = await postWithToken(SMOOTH_LINE_API_URL + requestPath, params).catch((err) => {
        const errMessage = err.response?.data?.message
        alert(`メッセージの送信に失敗しました - ${errMessage}`)
        throw new Error(err)
      })

      setState((prevState) => {
        return {
          ...prevState,
          messages: [camelCase(res.data.message) as MessageType, ...prevState.messages],
        }
      })
      resetMessageForm()
    },
    [postWithToken, resetMessageForm, selectedTalkRoom, setState, state.newMessage]
  )

  const sendImageMessage = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!selectedTalkRoom) {
      alert("トークルームが選択されていません。")
      return
    }

    const requestPath = selectedTalkRoom.postImagePath
    const params = new FormData()

    params.append("message[image]", e.target.files[0])

    postWithToken(SMOOTH_LINE_API_URL + requestPath, params)
      .then(() => {
        fetchTalkRooms()
        resetMessageForm()
      })
      .catch((error) => {
        alert(error)
      })
  }

  return {
    handleMessageForm,
    sendTextMessage,
    messageFormTextareaRef,
    resetMessageForm,
    sendImageMessage,
    appendMessage,
  }
}
