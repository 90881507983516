import { useCallback, useRef } from "react"
import { useRecoilState } from "recoil"
import { lineChatMessagesState } from "src/store/lineChatState"
import { useLineChatState } from "./useLineChatState"
import { useSelectedTalkRoom } from "./useSelectedTalkRoom"
import { useValueRef } from "src/hooks/useValueRef"
import { SMOOTH_LINE_API_URL } from "src/lib/constants"
import { useSmoothApi } from "src/hooks/useSmoothApi"
import { camelCase } from "src/lib/aikagi"
import { MessageType } from "../components/Message"

export const useFetchMessages = () => {
  const { lineChatState, setLineChatState } = useLineChatState()
  const [messages, setMessages] = useRecoilState(lineChatMessagesState)
  const { selectedTalkRoom } = useSelectedTalkRoom()
  const messagesContainerRef = useRef<HTMLDivElement>()
  const stateRef = useValueRef(lineChatState)
  const intervalRef = useRef(null)
  const fetching = useRef<boolean>(false)
  const { getWithToken } = useSmoothApi()
  const { selectedTalkRoomId } = useSelectedTalkRoom()

  // const messageParser = (results: any) => JSON.parse(results.data.messages).data
  const messageParser = (results: any) => {
    return results.data.messages.map((d: any) => camelCase(d)) as MessageType[]
  }

  const fetchInitialMessages = useCallback(async () => {
    const state = stateRef.current

    if (fetching.current) return
    fetching.current = true

    const talkRoom = state.talkRooms?.find((d) => d.id === selectedTalkRoomId)

    if (!talkRoom) {
      console.log("fetchInitialMessages(): トークルームが選択されていません。")
      return
    }

    const results = await getWithToken(
      `${SMOOTH_LINE_API_URL}/base_api/smoother/chat_rooms/${talkRoom.id}/messages`
    ).catch((e) => {
      alert("メッセージの取得に失敗しました。")
      throw new Error(e)
    })

    const messages = messageParser(results)

    fetching.current = false
    if (messages.length) {
      setMessages(messages)
    }
  }, [getWithToken, selectedTalkRoomId, setMessages, stateRef])

  const fetchNewMessages = useCallback(async () => {
    const state = stateRef.current
    if (fetching.current) return
    fetching.current = true

    const talkRoom = state.talkRooms?.find((d) => d.id === selectedTalkRoomId)

    if (!talkRoom) {
      console.log("fetchNewMessages: トークルームが選択されていません。")
      return
    }

    const messages = state.messages
    const maxId = messages.length
      ? Math.max(
          ...messages.map((message) => {
            return Number(message.id)
          })
        )
      : null

    const requestPath = talkRoom.getMessagesPath
    const params = maxId ? { max_id: maxId } : {}

    const results = await getWithToken(SMOOTH_LINE_API_URL + requestPath, {
      params: params,
    }).catch((e) => {
      console.error(e)
      alert("メッセージの取得に失敗しました。")
      throw new Error(e)
    })

    const newMessages = messageParser(results)

    fetching.current = false

    setMessages((currentMessages) => {
      const messages = [...currentMessages]
      messages.unshift(...newMessages)

      return messages
    })
  }, [getWithToken, selectedTalkRoomId, setMessages, stateRef])

  const fetchPreviousMessages = useCallback(() => {
    if (lineChatState.fetchingMessages) return

    const talkRoom = selectedTalkRoom

    if (!talkRoom) {
      console.log("fetchPreviousMessages(): トークルームが選択されていません。")
      return
    }

    setLineChatState((prevState) => ({
      ...prevState,
      fetchingMessages: true,
    }))

    const minId = messages.length
      ? messages
          .map((message) => {
            return Number(message.id)
          })
          .sort((a, b) => {
            return a - b
          })[0]
      : null

    const params = minId ? { min_id: minId } : {}

    getWithToken(`${SMOOTH_LINE_API_URL}/base_api/smoother/chat_rooms/${talkRoom.id}/messages`, {
      params: params,
    })
      .then((results) => {
        const messages = messageParser(results)

        setLineChatState((prevState) => {
          const messagesToDisplay = [...prevState.messages]

          messagesToDisplay.push(...messages)

          return {
            ...prevState,
            messages: messagesToDisplay,
            fetchingMessages: false,
          }
        })
      })
      .catch(() => {
        alert("メッセージの取得に失敗しました。")
      })
  }, [lineChatState.fetchingMessages, selectedTalkRoom, setLineChatState, messages, getWithToken])

  const scrollToBottomMessage = useCallback(() => {
    if (!messagesContainerRef.current) {
      return
    }

    const scrolledAmount =
      messagesContainerRef.current.scrollTop + messagesContainerRef.current.clientHeight
    const scrollAmount =
      messagesContainerRef.current.scrollHeight - messagesContainerRef.current.clientHeight

    // 古いメッセージを閲覧中、勝手に最下部にスクロールされないようにする。
    if (scrolledAmount === messagesContainerRef.current.scrollHeight) {
      messagesContainerRef.current.scroll(0, scrollAmount)
    }
  }, [])

  const startFetchMessageLooper = useCallback(() => {
    if (intervalRef.current !== null) return

    intervalRef.current = setInterval(() => {
      stateRef.current.messages.length ? fetchNewMessages() : fetchInitialMessages()
      scrollToBottomMessage()
    }, 5000)
  }, [fetchInitialMessages, fetchNewMessages, scrollToBottomMessage, stateRef])

  const stopFetchMessagesLooper = useCallback(() => {
    clearInterval(intervalRef.current)
    intervalRef.current = null
  }, [])

  const resetMessages = () => {
    if (messages.length) {
      setLineChatState((prevState) => {
        return { ...prevState, messages: [] }
      })
    }
  }

  return {
    fetchInitialMessages,
    messagesContainerRef,
    resetMessages,
    scrollToBottomMessage,
    fetchPreviousMessages,
    startFetchMessageLooper,
    stopFetchMessagesLooper,
  }
}
