import React, { memo, useState } from "react"
import {
  OpenTalkRoomInfoSection,
  OpenTalkRoomsSection,
  TalkRoom,
  User,
} from "src/features/chat/types"
import { TalkHeaderStatusDisplay } from "./TalkHeaderStatusDisplay"
import { BsClipboard, BsPersonCircle, BsShareFill } from "react-icons/bs"
import { CopyToClipboard } from "src/components/CopyToClipboard"
import { SnackBarCard } from "src/lib/ui/snack-bar-card"
import styled from "styled-components"
import { font, generateClassName, mq_min, Text } from "@smoothincorg/smooth-ui"
import { MdOutlineNavigateBefore } from "react-icons/md"
import { AiTwotoneSetting } from "react-icons/ai"
import { NO_AVATAR_PATH } from "./Message"
import Link from "next/link"

// Line Chat Relative Components

export type TalkHeaderProps = {
  talkRoom: TalkRoom
  user: User
  openTalkRoomsSection: OpenTalkRoomsSection
  openTalkRoomInfoSection: OpenTalkRoomInfoSection
  className?: string
}

const { getRootClassName, className } = generateClassName("TalkHeader")

// eslint-disable-next-line react/display-name
const Component: React.VFC<TalkHeaderProps> = memo((props) => {
  const [imageError, setImageError] = useState<number[]>([])
  const talkRoom = props.talkRoom
  const user = props.user

  if (!talkRoom) {
    return null
  }

  return (
    <div className={getRootClassName(props)}>
      {user && (
        <div className={`${className}__user-info`}>
          <div className={`${className}__navigate-before`}>
            <MdOutlineNavigateBefore
              onClick={props.openTalkRoomsSection}
              className="material-icons"
            />
          </div>

          <div className={`${className}__avater`}>
            {props.user && !imageError.includes(props.user.id) ? (
              // eslint-disable-next-line @next/next/no-img-element
              <img
                src={props.user.avatarUrl || NO_AVATAR_PATH}
                onError={() => {
                  setImageError((c) => [...c, props.user.id])
                }}
                alt="avater"
              />
            ) : (
              <BsPersonCircle width={40} height={40} />
            )}
          </div>

          <div className={`${className}__profile`}>
            <Text size={16} weight="bold">
              <Link href={"/admin/users/" + props.user?.id} style={{ textDecoration: "underline" }}>
                {talkRoom.userFullNameFurigana}
              </Link>
            </Text>

            <Text size={12}>ID: {user.id}</Text>
            <CopyToClipboard
              text={`${user.id}_${talkRoom.userFullNameFurigana.replace(/\s+/g, "")}`}
              onCopy={() => {
                SnackBarCard.fire({ title: "コピーしました" })
              }}
            >
              <BsClipboard />
            </CopyToClipboard>
            <CopyToClipboard
              text={`https://cs.smooth.jp/chat/${user.id}`}
              onCopy={() => {
                SnackBarCard.fire({ title: "コピーしました" })
              }}
            >
              <BsShareFill />
            </CopyToClipboard>
          </div>
        </div>
      )}

      <TalkHeaderStatusDisplay
        // States
        talkRoom={talkRoom}
      />

      <button className={`${className}__settings`} onClick={props.openTalkRoomInfoSection}>
        <AiTwotoneSetting />
      </button>
    </div>
  )
})

export const TalkHeader = styled(Component)`
  box-shadow: 0 6px 12px rgb(0 0 0 / 6%);
  height: 56px;
  display: flex;
  align-items: center;

  ${mq_min("sp_wide")} {
    padding: 0 20px;
  }

  .smooth-ui-TalkHeaderStatusDisplay {
    ${font(12)};
    margin-left: auto;
  }

  .${className} {
    &__user-info {
      display: flex;
      align-items: center;
    }

    &__navigate-before {
      height: 30px;

      > svg {
        width: 30px;
        height: 100%;
      }

      ${mq_min("sp_wide")} {
        display: none;
      }
    }

    &__profile {
      display: flex;
      align-items: center;

      > .smooth-ui-Text {
        margin-right: 12px;
      }

      > svg {
        margin-right: 10px;
      }
    }

    &__avater {
      width: 40px;
      height: 40px;
      border-radius: 25px;
      overflow: hidden;
      margin-right: 10px;

      > svg {
        width: 100%;
        height: 100%;
      }
    }

    &__settings {
      height: 30px;

      > svg {
        width: 30px;
        height: 100%;
      }

      ${mq_min("sp_wide")} {
        display: none;
      }
    }
  }
`
