import React, { memo, Ref } from "react"
import { GetSmoother, User } from "src/features/chat/types"
import { useChatRoomView } from "src/features/chat/hooks/useChatRoomView"
import { Message, MessageType } from "./Message"
import styled from "styled-components"
import { color, generateClassName } from "@smoothincorg/smooth-ui"

export type MessagesProps = {
  messages: MessageType[]
  getSmoother: GetSmoother
  user: User
  messagesContainerRef: Ref<HTMLDivElement>
  className?: string
} & Pick<ReturnType<typeof useChatRoomView>, "chatScrollCallback">

const { getRootClassName } = generateClassName("Messages")

// eslint-disable-next-line react/display-name
const Component: React.FC<MessagesProps> = memo((props) => {
  // const fetcher = async (url: string) => {
  //   const results = await getWithToken(url)
  //   console.log({ url, results })
  //   return results.data.messages.map((d: MessageType) =>
  //     camelCase(d)
  //   ) as MessageType[]
  // }

  // const { selectedTalkRoomId } = useSelectedTalkRoom()

  // const { data, mutate } = useSWRInfinite(
  //   (pageIndex, previousPageData: MessageType[]) => {
  //     if (!selectedTalkRoomId) return null
  //     if (previousPageData && !previousPageData.length) return null

  //     const query = previousPageData?.[0]?.id
  //       ? `?min_id=${previousPageData?.[0]?.id}`
  //       : ``

  //     const key = `${SMOOTH_LINE_API_URL}/base_api/smoother/chat_rooms/${selectedTalkRoomId}/messages${query}`

  //     return key
  //   },
  //   fetcher
  // )

  const messageData = [...(props.messages ?? [])]
  // const messageData = [...(data?.flat() ?? [])]
  const messages = [...messageData].sort((a, b) => (a.id > b.id ? -1 : 0))

  return (
    <div
      id="messages_container"
      ref={props.messagesContainerRef}
      onScroll={props.chatScrollCallback}
      className={getRootClassName(props)}
    >
      {messages.map((message) => {
        return (
          <Message
            key={message.id}
            // States
            message={message}
            user={props.user}
            // Functions
            getSmoother={props.getSmoother}
            data-id={message.id}
          />
        )
      })}
    </div>
  )
})

const StyledComponent = styled(Component)`
  display: flex;
  align-items: flex-start;
  flex-direction: column-reverse;
  background-color: ${color.lightGray};
  padding: 0px 16px;
  height: 100%;
  overflow: scroll;
  width: 100%;

  .smooth-ui-Message {
    margin-bottom: 36px;
  }
`

export const Messages = StyledComponent
