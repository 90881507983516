import { generateClassName, Text } from "@smoothincorg/smooth-ui"
import React from "react"
import styled from "styled-components"
import { useToggleStatusFilter } from "src/features/chat/hooks/useToggleStatusFilter"
import { AiFillCheckCircle, AiOutlineCheckCircle } from "react-icons/ai"

export const STATUS_FILTER_OPTION_STATUSES = {
  DEMANDED: "demanded",
  UNDEMANDING: "undemanding",
  ACTION_REQUIRED: "action_required",
  REPLIED: "replied",
  NONE: "none",
} as const

type StatusFilterOptionStatus =
  (typeof STATUS_FILTER_OPTION_STATUSES)[keyof typeof STATUS_FILTER_OPTION_STATUSES]

const texts: { [key in StatusFilterOptionStatus]: string } = {
  [STATUS_FILTER_OPTION_STATUSES.DEMANDED]: "督促済",
  [STATUS_FILTER_OPTION_STATUSES.UNDEMANDING]: "未督促",
  [STATUS_FILTER_OPTION_STATUSES.ACTION_REQUIRED]: "要返信",
  [STATUS_FILTER_OPTION_STATUSES.REPLIED]: "返信済",
  [STATUS_FILTER_OPTION_STATUSES.NONE]: "無し",
}

type Props = Pick<
  ReturnType<typeof useToggleStatusFilter>,
  "toggleStatusFilterCheckbox" | "checkedStatuses"
> & {
  status: StatusFilterOptionStatus
  className?: string
}

const { getRootClassName, className } = generateClassName("StatusFilterOption")

const Component: React.VFC<Props> = (props) => {
  const classNames = [
    getRootClassName(props),
    props.checkedStatuses[props.status] && "checked",
  ].join(" ")

  return (
    <div onClick={() => props.toggleStatusFilterCheckbox(props.status)} className={classNames}>
      <div className={`${className}__icons`}>
        {props.checkedStatuses[props.status] ? (
          <AiFillCheckCircle width={18} />
        ) : (
          <AiOutlineCheckCircle />
        )}
      </div>
      <Text size={16}>{texts[props.status]}</Text>
    </div>
  )
}

const StyledComponent = styled(Component)`
  display: flex;
  align-items: center;
  padding: 8px 4px;
  cursor: pointer;

  .${className} {
    &__icons {
      height: 18px;
      margin-right: 8px;
      > svg {
        width: 18px;
      }
    }
  }
`

export const StatusFilterOption = StyledComponent
