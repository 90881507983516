import React from "react"
import { useRefreshTalkRooms } from "src/features/chat/hooks/useRefreshTalkRooms"
import { BiRefresh } from "react-icons/bi"

type Props = Pick<ReturnType<typeof useRefreshTalkRooms>, "refreshTalkRooms">

export const RefreshButton: React.FC<Props> = (props) => {
  return (
    <button onClick={props.refreshTalkRooms} className="p-line-client__talk-room-refresh-button">
      <BiRefresh />
    </button>
  )
}
