import { useCallback, useState } from "react"
import { useAuthState } from "src/features/auth/hooks/useAuthState"
import { NotionStatus, NOTION_STATUS } from "../components/NotionCreateButton"
import { NotionCreateButtonProps } from "../components/NotionCreateButton"
import { GetTokenFromCookies } from "src/lib/utils/cookies"

export type SubmitArgs = {
  resource: Pick<
    NotionCreateButtonProps,
    | "id"
    | "first_name"
    | "first_furigana"
    | "last_name"
    | "last_furigana"
    | "store_id"
    | "moving_on"
    | "property_name"
  >
}

export const useSubmitNotionUser = () => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [inNotion, setInNotion] = useState<NotionStatus>(NOTION_STATUS.LOADING)
  const { auth } = useAuthState()

  const handleSubmit = useCallback(
    async (body: SubmitArgs) => {
      const payload = {
        user_id: body.resource.id,
        first_name: body.resource.first_name,
        last_name: body.resource.last_name,
        first_furigana: body.resource.first_furigana,
        last_furigana: body.resource.last_furigana,
        store_id: body.resource.store_id,
        property_name: body.resource.property_name,
        moving_on: body.resource.moving_on,
      }
      setIsSubmitting(true)
      const res = await fetch(`/api/notion-users`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          Authorization: `Bearer ${GetTokenFromCookies()}`,
        },
        body: JSON.stringify(payload),
      })
      if (res.ok) {
        alert(`ユーザー${body.resource.id}のNotionページを作成しました〜`)
        setIsSubmitting(false)
        return
      }
      const json = await res.json()
      alert(`err: ${JSON.stringify(json)}`)
    },
    [auth?.user]
  )

  const checkIsInNotion = useCallback(
    async (id: number) => {
      setInNotion("LOADING")
      const res = await fetch(`/api/notion-users/${id}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${GetTokenFromCookies()}`,
        },
      })

      const result = await res.json()

      if (result.results?.length) {
        setInNotion("TRUE")
        return
      }

      setInNotion("FALSE")
    },
    [auth.user]
  )

  return {
    isSubmitting,
    handleSubmit,
    checkIsInNotion,
    inNotion,
  }
}
