import React from "react"
import styled from "styled-components"
import useSWR from "swr"
import { CopyToClipboard } from "src/components/CopyToClipboard"
import { BsClipboard } from "react-icons/bs"
import { font } from "@smoothincorg/smooth-ui"
import { SnackBarCard } from "src/lib/ui/snack-bar-card"
import { useSmoothApi } from "src/hooks/useSmoothApi"
import { SMOOTH_LINE_API_URL } from "src/lib/constants"

export const useInitialGurusakuMessagesSWR = (proposalId: number) => {
  const url = `${SMOOTH_LINE_API_URL}/base_api/smoother/proposals/${proposalId}/initial_gurusaku_messages`
  const { getWithToken } = useSmoothApi()

  const latestFetcher = async () => {
    if (!proposalId) return []
    const res = await getWithToken(url)
    const data = res.data.initial_gurusaku_messages as string[][]

    return data
  }

  const initialGurusakuMessagesSWR = useSWR(url, latestFetcher)

  return {
    initialGurusakuMessagesSWR,
  }
}

export const InitialGurusakuMessagesClipboardContainer: React.VFC<{
  proposalId: number
}> = (props) => {
  const { initialGurusakuMessagesSWR } = useInitialGurusakuMessagesSWR(props.proposalId)

  return <InitialGurusakuMessagesClipboard messages={initialGurusakuMessagesSWR.data} />
}

type Props = {
  className?: string
  messages: string[][]
}

const MESSAGES_TEXTS = ["グループ作成後メッセージ", "物件共有メッセージ"] as const

const Component: React.VFC<Props> = (props) => {
  return (
    <div className={props.className}>
      {props.messages?.map((message, index) => (
        <div className={`${props.className}__message`} key={message[0]}>
          <CopyToClipboard
            text={message.join("\n")}
            onCopy={() => {
              SnackBarCard.fire({ title: "コピーしました" })
            }}
          >
            <button>
              <BsClipboard style={{ marginRight: 2 }} />
              {MESSAGES_TEXTS[index]}
            </button>
          </CopyToClipboard>
        </div>
      ))}
    </div>
  )
}

const StyledComponent = styled(Component)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  &__message {
    ${font(11)};
    padding: 2px 0;

    > button {
      display: flex;
      align-items: center;
    }
  }
`

export const InitialGurusakuMessagesClipboard = StyledComponent
