import React, { useMemo } from "react"
import { produce } from "immer"
import { Proposal, SimplifiedExamination, User } from "src/features/chat/types"
import styled from "styled-components"
import { generateClassName, Text } from "@smoothincorg/smooth-ui"
import { useMovingApplications } from "../hooks/useMovingApplications"
import { getLatest } from "src/lib/utils/model-utils"
import dayjs from "dayjs"
import { useIsMCI } from "src/hooks/useIsMCI"
import { useMzdaoStatus } from "src/hooks/useMzdaoStatus"
import { useSmoothApi } from "src/hooks/useSmoothApi"
import { SMOOTH_SMOOTHER_API_URL } from "src/lib/constants"

type Props = {
  user: User
  userFullNameFurigana: string
  latestProposal: Proposal
  getSimplifiedExamination: (id: number) => SimplifiedExamination
  className?: string
}

const { getRootClassName } = generateClassName("ProposalStatus")

export const Component: React.FC<Props> = (props) => {
  const { user, latestProposal = {} as Proposal } = props
  const latestSimplifiedExamination = props.getSimplifiedExamination(user.id)
  const { getAdoptedMovingApplication } = useMovingApplications()
  const { mciNotionDocument } = useIsMCI(props.latestProposal?.id)
  const { postWithToken, deleteWithToken } = useSmoothApi()

  const status = useMemo(() => {
    const initialValue = {
      request_condition: "",
      simplified_examination_apply: "未入力",
      simplified_examination_result: "未実施",
      how_to_ask_store: "未設定",
      about_schedule: "未設定",
      store_line_group_in: "未作成",
      store_email_group_in: "未作成",
      current_status: 0,
      store_name: "未設定",
      moving_target_on: "未設定",
      main_examination_result: "未設定",
      v3_agreement: "未同意",
      v2023dec_agreement: "未同意",
      jukyaku: false,
      block: false,
    }

    return produce(initialValue, (draft) => {
      if (!latestProposal || !user) return

      const requestConditionCompleted = (latestProposal.introductionStatus & 4) > 0
      const simplifiedExaminationApplied = (latestProposal.introductionStatus & 16) > 0
      const isBlocked = (user.status & 2) > 0

      if (requestConditionCompleted) draft.request_condition = "完了"
      if (simplifiedExaminationApplied) draft.simplified_examination_apply = "入力済"
      draft.block = isBlocked

      if ((latestProposal.introductionStatus & 2048) > 0) {
        draft.simplified_examination_result = "通過"
      }

      if ((latestProposal.introductionStatus & 4096) > 0) {
        draft.simplified_examination_result = "不通過"
      }
      if ((latestProposal.introductionStatus & 8192) > 0) {
        draft.simplified_examination_result = "要確認"
      }

      if ((latestProposal.searchStatus & 1) > 0) {
        draft.how_to_ask_store = "来店希望"
      }

      if ((latestProposal.searchStatus & 2) > 0) {
        draft.how_to_ask_store = "内見希望"
      }

      if ((latestProposal.searchStatus & 4) > 0) {
        draft.how_to_ask_store = "オンラインチャット提案希望"
      }

      if ((latestProposal.searchStatus & 8) > 0) {
        draft.how_to_ask_store = "オンライン電話提案希望"
      }

      if ((latestProposal.searchStatus & 128) > 0) {
        draft.about_schedule = "希望日程送信済み"
      }

      if ((latestProposal.searchStatus & 256) > 0) {
        draft.about_schedule = "希望日程を不動産会社が承認済み"
      }
      if ((latestProposal.searchStatus & 512) > 0) {
        draft.about_schedule = "来店済み"
      }

      if ((latestProposal.searchStatus & 32) > 0) {
        draft.store_line_group_in = "作成済み"
      }

      if ((latestProposal.searchStatus & 64) > 0) {
        draft.store_email_group_in = "作成済み"
      }

      if ((user.status & 8192) > 0) {
        draft.v3_agreement = "同意済み"
      }

      if ((user.status & 16384) > 0) {
        draft.v2023dec_agreement = "同意済み"
      }

      if ((latestProposal.introductionStatus & 32) > 0) {
        draft.jukyaku = true
      }
    })
  }, [latestProposal, user])

  const latestMainExamination = getLatest(
    getAdoptedMovingApplication(props.latestProposal?.id)?.mainExaminations
  )

  const lendableLoan = latestMainExamination?.mainExaminationScore?.lendableLoan

  const lendableLoanText =
    !lendableLoan || isNaN(Number(lendableLoan)) ? "なし" : `${lendableLoan / 10000}万円`

  const smoothExaminedAtText = latestMainExamination?.smoothExaminedAt
    ? dayjs(latestMainExamination?.smoothExaminedAt).format("YYYY-MM-DD HH:mm")
    : "なし"

  const mainExaminationScoreResult = latestMainExamination?.mainExaminationScore?.result ?? "未実施"

  const mciText = useMemo(() => {
    if (mciNotionDocument === null) return "読み込み中…"
    return mciNotionDocument ? "そう" : "ちがう"
  }, [mciNotionDocument])

  const movingTargetOn = props.latestProposal?.latestSearchHouse?.movingTargetOn

  const mzdaoStatus = useMzdaoStatus({
    userId: user.id,
  })

  const tableContent = useMemo<[string, string][]>(
    () => [
      ["希望条件登録", status.request_condition],
      ["審査情報", status.simplified_examination_apply],
      ["V3系の規約同意", status.v3_agreement],
      ["2023decの規約同意", status.v2023dec_agreement],
      ["担当店舗", user.latestProposalStoreName],
      ["本審査結果", mainExaminationScoreResult ?? "未実施"],
      ["審査実施日", smoothExaminedAtText],
      ["部分分割金額", lendableLoanText],
      ["流入経路", user.analyticsLogParametersPath],
      ["加盟店とのLINEグループ", status.store_line_group_in],
      ["加盟店とのメールグループ", status.store_email_group_in],
      ["ブロック", status.block ? "ブロック済" : "無"],
      ["受客？", status.jukyaku ? "そう" : "ちがう"],
      ["譲渡通知済?", mciText],
      ["引越希望時期", movingTargetOn],
    ],
    [
      user.latestProposalStoreName,
      user.analyticsLogParametersPath,
      status.request_condition,
      status.simplified_examination_apply,
      status.v3_agreement,
      status.v2023dec_agreement,
      status.store_line_group_in,
      status.store_email_group_in,
      status.block,
      status.jukyaku,
      mainExaminationScoreResult,
      smoothExaminedAtText,
      lendableLoanText,
      mciText,
      movingTargetOn,
    ]
  )

  return (
    <div className={getRootClassName(props)}>
      <table className="p-line-client__propsal-status-table">
        <tbody>
          {tableContent.map(([th, td]) => (
            <tr key={th}>
              <th>
                <Text size={12} weight="bold">
                  {th}
                </Text>
              </th>
              <td>
                <Text size={12}>{td}</Text>
              </td>
            </tr>
          ))}
          {latestSimplifiedExamination && (
            <tr>
              <th>
                <Text size={12} weight="bold">
                  希望支払い回数/金利
                </Text>
              </th>
              <td>
                <Text size={12}>
                  {latestSimplifiedExamination.attributes.formatNumberOfPayment}
                  /年率
                  {latestSimplifiedExamination.attributes.formatAnnualInterestRate}
                </Text>
              </td>
            </tr>
          )}
          <tr>
            <th>
              <Text size={12} weight="bold">
                MZDAOかどうか
              </Text>
            </th>
            <td>
              {mzdaoStatus.isValidating ? (
                <Text size={12}>読み込み中…</Text>
              ) : (
                <select
                  value={mzdaoStatus.data?.isMzdaoUser ? "1" : "2"}
                  style={{
                    fontSize: "12px",
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                    padding: "4px 8px",
                  }}
                  onChange={async () => {
                    const confirmed = window.confirm(
                      "MZDAOのステータスを変更します。よろしいですか？"
                    )

                    if (!confirmed) return

                    try {
                      if (mzdaoStatus.data?.isMzdaoUser === true) {
                        await deleteWithToken(
                          `${SMOOTH_SMOOTHER_API_URL}/users/${user.id}/mzdao_users/destroy_all`,
                          {}
                        )
                      }

                      if (mzdaoStatus.data?.isMzdaoUser === false) {
                        await postWithToken(
                          `${SMOOTH_SMOOTHER_API_URL}/users/${user.id}/mzdao_users`
                        )
                      }
                    } catch (e) {
                      alert("MZDAOステータスの変更に失敗しました")
                      console.error(e)
                    }

                    await mzdaoStatus.mutate()
                  }}
                >
                  <option value="1">YES</option>
                  <option value="2">NO</option>
                </select>
              )}
            </td>
          </tr>
          <tr>
            <th>
              <Text size={12} weight="bold">
                お祝い金
              </Text>
            </th>
            <td>
              <Text size={12}>{latestProposal.incentiveStatus}</Text>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

const StyledComponent = styled(Component)`
  &-actions {
    margin-top: 16px;
  }

  table {
    width: 100%;
  }
`

export const ProposalStatus = StyledComponent
