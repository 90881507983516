import React from "react"
import { useLineChatState } from "../hooks/useLineChatState"
import { useSelectedTalkRoom } from "../hooks/useSelectedTalkRoom"
import { useSmootherMemos } from "../hooks/useSmootherMemo"
import { useAssignSmoother } from "../hooks/useAssignSmoother"
import { useGetBaseData } from "../hooks/useGetBaseData"
import { useHandleMemoForm } from "../hooks/useHandleMemoForm"
import { useToggleStatusFilter } from "../hooks/useToggleStatusFilter"
import { useChatRoomView } from "../hooks/useChatRoomView"
import { TalkRoomInfo } from "src/features/chat/components/TalkRoomInfo"

export const TalkRoomInfoContainer: React.VFC = () => {
  const { lineChatState } = useLineChatState()
  const { toggleStatus } = useToggleStatusFilter()
  const { selectedTalkRoom } = useSelectedTalkRoom()

  const { memosRef, closeMemoForm, saveMemo, destroyMemo, expandMemo, foldMemo } =
    useSmootherMemos()

  const { closeAssignSmootherModal, openAssignSmootherModal } = useAssignSmoother()

  const { getUser, getProposal, getSimplifiedExamination, getSmoother } = useGetBaseData()

  const { handleMemoBodyForm, handleMemoTitleForm, openMemoForm } = useHandleMemoForm()

  const { openChatSection } = useChatRoomView()

  return (
    <TalkRoomInfo
      // States
      memos={lineChatState.memos}
      memoFormOpened={lineChatState.memoFormOpened}
      memoForm={lineChatState.memoForm}
      smoothers={lineChatState.smoothers}
      talkRoom={selectedTalkRoom}
      displayedSections={lineChatState.displayedSections}
      // References
      memosRef={memosRef}
      // Functions
      closeMemoForm={closeMemoForm}
      closeAssignSmootherModal={closeAssignSmootherModal}
      saveMemo={saveMemo}
      destroyMemo={destroyMemo}
      getUser={getUser}
      expandMemo={expandMemo}
      foldMemo={foldMemo}
      handleMemoBodyForm={handleMemoBodyForm}
      handleMemoTitleForm={handleMemoTitleForm}
      getProposal={getProposal}
      getSimplifiedExamination={getSimplifiedExamination}
      getSmoother={getSmoother}
      openAssignSmootherModal={openAssignSmootherModal}
      openMemoForm={openMemoForm}
      toggleStatus={toggleStatus}
      openChatSection={openChatSection}
    />
  )
}
