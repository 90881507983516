import React, { ChangeEvent, memo } from "react"
import { DisplayedSections, TalkRoom } from "src/features/chat/types"
import { ProposalStatus } from "./ProposalStatus"
import { Memos } from "./Memos"
import { NotionCreateButton } from "./NotionCreateButton"
import { useSmootherMemos } from "../hooks/useSmootherMemo"
import { useHandleMemoForm } from "../hooks/useHandleMemoForm"
import { useAssignSmoother } from "../hooks/useAssignSmoother"
import { useToggleStatusFilter } from "../hooks/useToggleStatusFilter"
import { useGetBaseData } from "../hooks/useGetBaseData"
import styled from "styled-components"
import { color, generateClassName, mq, mq_min, Text, Title } from "@smoothincorg/smooth-ui"
import { MdOutlineNavigateBefore } from "react-icons/md"
import { boxShadow } from "src/lib/styles/variable"
import { Card } from "src/components/page-directory/Card"
import { Button } from "src/components/Button"
import { Border } from "src/components/Border"
import { TalkRoomActions } from "./TalkRoomActions"
import { MemoType } from "src/components/Memo"
import { Smoother } from "src/features/auth/hooks/useCurrentSmoother"
import { EMERGENCY_USERS } from "src/features/user/20230224_emergency_users"

type Props = {
  talkRoom: TalkRoom
  displayedSections: DisplayedSections
  openChatSection: () => void
  memoFormOpened: boolean
  smoothers: Smoother[]
  memos: MemoType[]
  className?: string
} & Pick<
  ReturnType<typeof useSmootherMemos>,
  "closeMemoForm" | "saveMemo" | "destroyMemo" | "expandMemo" | "foldMemo" | "memosRef"
> &
  Pick<
    ReturnType<typeof useHandleMemoForm>,
    "handleMemoBodyForm" | "handleMemoTitleForm" | "openMemoForm" | "memoFormOpened" | "memoForm"
  > &
  Pick<
    ReturnType<typeof useAssignSmoother>,
    "openAssignSmootherModal" | "closeAssignSmootherModal"
  > &
  Pick<ReturnType<typeof useToggleStatusFilter>, "toggleStatus"> &
  Pick<
    ReturnType<typeof useGetBaseData>,
    "getSmoother" | "getUser" | "getSimplifiedExamination" | "getProposal"
  >

const { getRootClassName, className } = generateClassName("TalkRoomInfo")

const Select = styled.select`
  width: 60px;
  height: 20px;
  padding: 2px 4px;
  margin-left: auto;
  ${boxShadow.normal};
  border: 1px solid #000;
`

// eslint-disable-next-line react/display-name
const Component: React.VFC<Props> = memo((props) => {
  const user = props.getUser(props.talkRoom?.userId)
  const latestProposal = props.getProposal(user?.latestProposalId)

  if (!user || !props.talkRoom) {
    return null
  }

  return (
    <div
      className={`${getRootClassName(props)} ${
        props.displayedSections["talkRoomInfoSection"] == false && "u-display-none"
      }`}
    >
      <div className={`${className}__header`}>
        <MdOutlineNavigateBefore onClick={props.openChatSection} width={40} />
      </div>

      <div className={`${className}__content`}>
        <Card>
          <Title size={14} as="h3">
            {`ステータス`}
            <Select
              className="status"
              value={props.talkRoom.statusJa}
              // backgroundColor={'gradationBlue'}
              onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                const value = e.target.value as string
                switch (value) {
                  case "督促済": {
                    props.toggleStatus("督促済")
                    break
                  }
                  case "未督促": {
                    props.toggleStatus("未督促")
                    break
                  }
                  case "要対応": {
                    props.toggleStatus("要対応")
                    break
                  }
                  case "返信済": {
                    props.toggleStatus("返信済")
                    break
                  }
                  default: {
                    props.toggleStatus(props.talkRoom.statusJa)
                  }
                }
              }}
            >
              <option value="督促済">督促済</option>
              <option value="未督促">未督促</option>
              <option value="要対応">要対応</option>
              <option value="返信済">返信済</option>
              <option value="">なし</option>
            </Select>
          </Title>
          <Border />
          <ProposalStatus
            // States
            latestProposal={latestProposal}
            user={user}
            // Functions
            userFullNameFurigana={props.talkRoom.userFullNameFurigana}
            getSimplifiedExamination={props.getSimplifiedExamination}
          />
        </Card>

        <TalkRoomActions />

        <Card>
          <Title size={14} as="h3">
            {`Notion`}
          </Title>
          <Border />
          <NotionCreateButton />
        </Card>

        <Card>
          <Title size={14} as="h3">
            {`メモ`}
            <Button onClick={() => props.openMemoForm(null)}>新規作成</Button>
          </Title>
          <Border />
          {EMERGENCY_USERS.includes(user.id) ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: 16,
                padding: 20,
              }}
            >
              <Text size={16} weight="bold" color="red">
                202302_口座振替請求の失敗インシデント対象ユーザー
              </Text>
            </div>
          ) : null}
          <Memos
            // States
            memos={props.memos}
            memoFormOpened={props.memoFormOpened}
            // References
            memosRef={props.memosRef}
            // Functions
            saveMemo={props.saveMemo}
            destroyMemo={props.destroyMemo}
            expandMemo={props.expandMemo}
            foldMemo={props.foldMemo}
            getSmoother={props.getSmoother}
            openMemoForm={props.openMemoForm}
          />
        </Card>
      </div>
    </div>
  )
})

export const TalkRoomInfo = styled(Component)`
  background-color: ${color.lightGray};

  .${className} {
    &__header {
      width: 100%;
      background-color: ${color.white};
      height: 56px;
      ${boxShadow.normal};
      display: flex;
      align-items: center;
      position: fixed;
      justify-content: space-between;

      ${mq_min("sp_wide")} {
        display: none;
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 20px 5%;

      ${mq("sp_wide")} {
        padding-top: 80px;
      }

      > .smooth-ui-Card {
        width: 100%;
        margin-bottom: 12px;
        padding: 11px 14px;

        > .smooth-ui-Title {
          font-weight: bold;
          margin-bottom: 8px;
          display: flex;

          > .smooth-ui-Button {
            padding: 2px 4px;
            margin-left: auto;
          }
        }

        .smooth-ui-NotionCreateButton {
          margin: 8px auto;
        }
      }
    }

    &__anchors {
      display: flex;
      padding: 8px 0;

      > * {
        height: 20px;
        margin-right: 16px;

        > svg {
          height: 100%;
          width: 20px;
        }
      }
    }
  }
`
