import { useCallback } from "react"
import { useRecoilState } from "recoil"
import { lineChatState } from "src/store/lineChatState"

export const useGetBaseData = () => {
  const [state] = useRecoilState(lineChatState)

  const getMemo = useCallback(
    (memoId: number) => {
      return state.memos?.find((memo) => {
        return String(memo.id) === String(memoId)
      })
    },
    [state.memos]
  )

  const getSmoother = useCallback(
    (smootherId: number) => {
      return state.smoothers?.find((smoother) => {
        return Number(smoother.id) === Number(smootherId)
      })
    },
    [state.smoothers]
  )

  const getTalkRoom = useCallback(
    (talkRoomId: number) => {
      return state.talkRooms?.find((talkRoom) => {
        return String(talkRoom.id) === String(talkRoomId)
      })
    },
    [state.talkRooms]
  )

  const getUser = useCallback(
    (userId: number) => {
      return state.users?.find((user) => {
        return user.id === userId
      })
    },
    [state.users]
  )

  const getProposal = useCallback(
    (proposalId: number) => {
      return state.latest_proposals?.find((proposal) => {
        return String(proposal.id) === String(proposalId)
      })
    },
    [state.latest_proposals]
  )

  const getSimplifiedExamination = useCallback(
    (userId: number) => {
      return state.latest_simplified_examinations?.find((se) => {
        return String(se.relationships.user.data.id) === String(userId)
      })
    },
    [state.latest_simplified_examinations]
  )

  return {
    getMemo,
    getSmoother,
    getTalkRoom,
    getUser,
    getProposal,
    getSimplifiedExamination,
  }
}
