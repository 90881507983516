import React from "react"
import { useToggleStatusFilter } from "src/features/chat/hooks/useToggleStatusFilter"
import { BiFilter } from "react-icons/bi"

type Props = Pick<ReturnType<typeof useToggleStatusFilter>, "toggleStatusFilterDropdown">

const Component: React.FC<Props> = (props) => {
  return (
    <button
      onClick={props.toggleStatusFilterDropdown}
      className="p-line-client__talk-room-status-filter-button"
    >
      <BiFilter />
    </button>
  )
}

export const StatusFilter = Component
