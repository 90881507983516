import produce from "immer"
import React, { useCallback, useRef } from "react"
import { useRecoilState } from "recoil"
import { useGetBaseData } from "./useGetBaseData"
import { useSelectedTalkRoom } from "./useSelectedTalkRoom"
import { lineChatState } from "src/store/lineChatState"
import { useSmoothApi } from "src/hooks/useSmoothApi"
import { SMOOTH_SMOOTHER_API_URL } from "src/lib/constants/value"
import { MemoType } from "src/components/Memo"
import { useCurrentSmoother } from "src/features/auth"

/**
 * @deprecated 後々サーバー側と同じ型定義でリファクタリングするので
 * LineChat以外のコンポーネントやフック以外では使用しないでください。
 */
export type Memo = {
  id: number
  expanded: boolean
  links: {
    updateMemoPath: string
    destroyMemoPath: string
  }
  relationships: {
    memoable: {
      data: {
        id: number
      }
    }
  }
  attributes: {
    title: string
    createdAt: string
    body: string
  }
}

export const useSmootherMemos = () => {
  const [state, setState] = useRecoilState(lineChatState)
  const memosRef = useRef<HTMLDivElement>()
  const { selectedTalkRoom } = useSelectedTalkRoom()
  const { getUser, getMemo } = useGetBaseData()
  const { postWithToken, deleteWithToken, patchWithToken } = useSmoothApi()
  const memosState = state.memos
  const { smootherId } = useCurrentSmoother()

  const resetMemoForm = useCallback(() => {
    setState((prevState) => {
      const memoForm = produce(prevState.memoForm, (draft) => {
        draft.id = null
        draft.title = ""
        draft.body = ""
      })

      return { ...prevState, memoFormOpened: false, memoForm }
    })
  }, [setState])

  const closeMemoForm = useCallback(() => {
    resetMemoForm()
    setState((prevState) => {
      return { ...prevState, memoFormOpened: false }
    })
  }, [resetMemoForm, setState])

  const saveMemo = useCallback(
    async (e: React.FormEvent) => {
      e.preventDefault()

      const memoForm = state.memoForm
      const user = getUser(selectedTalkRoom?.userId)
      const memo = memoForm.id ? getMemo(memoForm.id) : null

      if (!memoForm.body) {
        alert("本文を入力してください。")
        return
      }

      if (!selectedTalkRoom) {
        alert("トークルームが選択されていません。")
        return
      }

      const params = {
        memo: {
          title: memoForm.title,
          body: memoForm.body,
          user_id: user.id,
          memoable_type: "Smoother",
          memoable_id: smootherId,
        },
      }

      if (memo === null) {
        const res = await postWithToken(
          SMOOTH_SMOOTHER_API_URL + `/deserialized_memos`,
          params
        ).catch((err) => {
          alert("メモの保存に失敗しました")
          throw new Error(err)
        })

        const created = res.data["data"] as MemoType

        setState((prevState) => {
          return { ...prevState, memos: [...prevState.memos, created] }
        })
        resetMemoForm()
      } else {
        const res = await patchWithToken(
          SMOOTH_SMOOTHER_API_URL + `/deserialized_memos/${memo.id}`,
          params
        ).catch((error) => {
          alert("メモの保存に失敗しました")
          throw new Error(error)
        })

        const updated = res.data["data"] as MemoType

        setState((prevState) => {
          return { ...prevState, memos: [...prevState.memos, updated] }
        })

        resetMemoForm()
      }
    },
    [
      getMemo,
      getUser,
      patchWithToken,
      postWithToken,
      resetMemoForm,
      selectedTalkRoom,
      setState,
      smootherId,
      state.memoForm,
    ]
  )

  const destroyMemo = useCallback(
    (memo: MemoType) => {
      if (!confirm("本当に削除しますか？")) {
        return
      }

      deleteWithToken(SMOOTH_SMOOTHER_API_URL + `/deserialized_memos/${memo.id}`, {})
        .then(() => {
          setState((prevState) => {
            return {
              ...prevState,
              memos: prevState.memos.filter((m) => m.id !== memo.id),
            }
          })
        })
        .catch(() => {
          alert("メモの削除に失敗しました。")
        })
    },
    [deleteWithToken, setState]
  )

  const expandMemo = useCallback(
    (memo: MemoType) => {
      setState((prevState) => {
        const memos = { ...prevState.memos }
        const selectedMemoIndex = memos
          .map((memo) => {
            return memo.id
          })
          .indexOf(memo.id)

        memos[selectedMemoIndex].expanded = true

        return { ...prevState, memos }
      })
    },
    [setState]
  )

  const foldMemo = useCallback(
    (memo: MemoType) => {
      setState((prevState) => {
        const memos = { ...prevState.memos }
        const selectedMemoIndex = memos
          .map((memo) => {
            return memo.id
          })
          .indexOf(memo.id)

        memos[selectedMemoIndex].expanded = false

        return { ...prevState, memos: memos }
      })
    },
    [setState]
  )

  const handleMemoBodyForm = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      e.persist()

      setState((prevState) => {
        const memoForm = { ...prevState.memoForm }

        memoForm.body = e.target.value

        return { ...prevState, memoForm }
      })
    },
    [setState]
  )

  return {
    memosRef,
    memosState,
    closeMemoForm,
    saveMemo,
    destroyMemo,
    expandMemo,
    foldMemo,
    handleMemoBodyForm,
  }
}
